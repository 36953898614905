import React, { useRef, useEffect, useState, useMemo, useCallback } from "react";
import H from "@here/maps-api-for-javascript";
import mugIcon from "../../assets/mug-map.png";
import carIcon from "../../assets/map_car.png";
import { Link } from "react-router-dom";
import { Button, ButtonStyles } from "../shared";
import styled from "styled-components";
import { colors } from "../../colors";
import { toast } from "react-toastify";

const Wrapper = styled.div`
  position: relative;
  height: 90%;
  height: calc(100% - 50px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1rem;
`;

const InfoOverlay = styled.div`
  ${({ isOpen }) => `
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    background-color: rgba(255,255,255,.85);
    height: calc(100% - 2rem);
    padding: 1rem;
    box-sizing: border-box;
    width: calc(100% - 2rem);
    z-index: 1;
    ${isOpen ? "" : "pointer-events: none;"}
    ${isOpen ? "" : "display: none;"}
  `}
`;

const DetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  max-height: calc(100% - 120px);
`;

const VenueImg = styled.img`
  max-width: 90%;
  max-height: 40%;
  height: 300px;
  width: 300px;
  object-fit: cover;
  object-position: center;
  margin: 1rem;

  @media (max-height: 500px) {
    display: none;
  }
`;

const VenueName = styled.h2`
    color: ${colors.primary};
    margin: 0;
    display: flex;
    align-items; center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
`;

const VenueInfo = styled.span`
    display: flex;
    align-items; center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
`;

const Phone = styled.a`
  text-decoration: none;
  color: inherit;
  word-break: break-all;

  @media (max-height: 330px) {
    display: none;
  }
`;

const VenueLink = styled.a`
    display: flex;
    align-items; center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 2px;
    line-height: 16px;
    color: ${colors.linkColor};
    word-break: break-all;
`;

const VenueWebsite = styled.a`
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  margin: 2px;
  color: ${colors.linkColor};
  word-break: break-all;
`;

const formatURL = (url) => {
  const prefix = "http://";
  const https = "https://";
  const subStr = url.substr(0, prefix.length);

  if (subStr !== prefix && subStr !== https) {
    url = prefix + url;
  }
  return url;
};

const ButtonContainer = styled.div`
  width: 100%;
  padding: 1rem 1rem 0rem 1rem;
  box-sizing: border-box;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 100%;

  @media (max-height: 375px) {
    grid-template-columns: 50% 50%;
  }
`;

const MyLocation = styled(Button)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1;
  margin: 0;
`;

const PrimaryLink = styled(Link)`
  ${ButtonStyles}
  background-color: ${colors.primary};
  color: ${colors.primaryOffset};
  text-decoration: none;
  padding: 0.5rem 1rem;
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem;
  margin: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
`;

const OverlayCloseButton = styled.button`
  ${ButtonStyles}
  width: 100%;
  background-color: ${colors.danger};
  padding: 0.5rem 1rem;
  color: white;
  box-sizing: border - box;
  font-size: 1rem;
  margin: 0;
`;

const mapIcon = new H.map.Icon(mugIcon, { size: { w: 25, h: 25 } });
const mapCar = new H.map.Icon(carIcon, { size: { w: 25, h: 25 } });

export const Map = ({ venues, onViewOffers }) => {
  const mapRef = useRef(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [map, setMap] = useState(null);
  const [buttonsDisabled, setButtonsDisabled] = useState(true);
  const platform = useMemo(() => {
    return new H.service.Platform({
      apikey: "IYhy984uzztIYuuXqbybzZsv0NUZr9ceFiFHOUlh7-4"
    });
  }, []);

  const ui = useMemo(() => {
    if (map) {
      return H.ui.UI.createDefault(map, platform.createDefaultLayers());
    }
    return null;
  }, [map, platform]);

  useEffect(() => {
    if (mapRef?.current && map === null) {
      const layers = platform.createDefaultLayers();
      const hereMap = new H.Map(mapRef.current, layers.raster.normal.map, {
        pixelRatio: window.devicePixelRatio,
        center: { lat: 39.742043, lng: -104.991531 },
        zoom: 8,
        engineType: H.map.render.RenderEngine.EngineType.P2D
      });
      window.addEventListener("resize", () => {
        hereMap.getViewPort().resize();
        setTimeout(() => {
          hereMap.getViewPort().resize();
        }, 1000);
      });
      if (hereMap) {
        new H.mapevents.Behavior(new H.mapevents.MapEvents(hereMap));
        setMap(hereMap);
      }
    }
  }, [map, platform]);

  const handleUpdateMyLocation = useCallback(
    (position) => {
      if (
        map &&
        typeof position?.coords?.latitude === "number" &&
        typeof position?.coords?.longitude === "number"
      ) {
        const marker = new H.map.Marker(
          { lat: position.coords.latitude, lng: position.coords.longitude },
          { icon: mapCar }
        );
        map.addObject(marker);
      }
    },
    [map]
  );

  const handleUpdateMyLocationError = useCallback(() => {
    console.error("Unable to update user location.");
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      window.navigator.geolocation.getCurrentPosition(handleUpdateMyLocation, handleUpdateMyLocationError);
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [handleUpdateMyLocation, handleUpdateMyLocationError]);

  const handleSetMyLocation = useCallback(
    (position) => {
      if (
        map &&
        typeof position?.coords?.latitude === "number" &&
        typeof position?.coords?.longitude === "number"
      ) {
        map.setCenter({ lat: position.coords.latitude, lng: position?.coords?.longitude });
      }
    },
    [map]
  );

  const handleGeolocationError = useCallback(() => {
    toast.error("Unable to find your location.", {
      position: "bottom-center",
      hideProgressBar: true,
      pauseOnHover: false,
      closeButton: false
    });
  }, []);

  const handleGetMyLocation = useCallback(() => {
    window.navigator.geolocation.getCurrentPosition(handleSetMyLocation, handleGeolocationError);
  }, [handleGeolocationError, handleSetMyLocation]);

  const handleClickMarker = useCallback(
    (evt) => {
      const { venue } = evt?.target?.data;
      setActiveMarker(venue);
      // setTimeout(() => {
      //   const { venue } = evt?.target?.data;
      //   setButtonsDisabled(true);
      //   setActiveMarker(venue);
      // }, 50);
    },
    [ui]
  );

  useEffect(() => {
    if (activeMarker?.venueId) {
      document.getElementById(activeMarker.venueId).click();
    }
    return () => {
      setActiveMarker(null);
    };
  }, [activeMarker]);

  useEffect(() => {
    if (map !== null && platform) {
      var service = platform.getSearchService();

      const handleVenue = (venue) => {
        const onResult = (result) => {
          // Add a marker for each location found
          if (result?.items[0]) {
            const item = result.items[0];
            const marker = new H.map.Marker(item.position, { data: { venue }, icon: mapIcon });
            marker.addEventListener("pointerup", handleClickMarker);
            map.addObject(marker);
          }
        };
        const address = `${venue.address}, ${venue.city} ${venue.state} ${venue.zipCode} `;
        service.geocode(
          {
            q: address
          },
          onResult,
          () => {
            setTimeout(() => {
              handleVenue(venue);
            }, 30000);
          }
        );
      };
      venues.forEach((venue) => {
        handleVenue(venue);
      });
    }
  }, [map, platform, handleClickMarker]);

  const closeOverlay = useCallback(() => {
    setActiveMarker(null);
  }, []);

  // const openOverlay = useCallback(() => {
  //   setOverlayOpen(true);
  // }, []);

  const handleViewOffers = useCallback(() => {
    setActiveMarker(null);
    if (onViewOffers) {
      onViewOffers();
    }
  }, [onViewOffers]);

  useEffect(() => {
    let timeout;
    if (buttonsDisabled) {
      timeout = setTimeout(() => {
        setButtonsDisabled(false);
      }, 100);
    }
    return () => window.clearTimeout(timeout);
  }, [buttonsDisabled]);

  return (
    <Wrapper>
      <div ref={mapRef} style={{ height: "100%", width: "100%", position: "relative" }}>
        <MyLocation onClick={handleGetMyLocation}>My Location</MyLocation>
        {activeMarker?.venueId && (
          <PrimaryLink
            id={activeMarker.venueId}
            to={`/member/venue/${activeMarker.venueId}`}
            data-id={activeMarker.venueId}
            onClick={handleViewOffers}
          >
            View Offers
          </PrimaryLink>
        )}
      </div>
      {/* <InfoOverlay isOpen={!!activeMarker}>
        {activeMarker ?
          <>
            <DetailWrapper>
              <VenueImg src={`${process.env.REACT_APP_IMAGE_ENDPOINT}/${activeMarker.image}`} />

              <VenueName key="name">{activeMarker.name}</VenueName>

              <Phone href={`tel:${activeMarker.phone}`}>{activeMarker.phone}</Phone>
              <VenueInfo key="website">
                <VenueWebsite href={formatURL(activeMarker.website)} target="_blank">
                  {activeMarker.website}
                </VenueWebsite>
              </VenueInfo>

              <VenueLink href={`https://maps.google.com/?q=${activeMarker.address},
          ${activeMarker.city},
          ${activeMarker.state} ${activeMarker.zipCode}`}>
                {`${activeMarker.address},`}
                <br />
                {`${activeMarker.city},
              ${activeMarker.state} ${activeMarker.zipCode}`}
              </VenueLink>
            </DetailWrapper>
            <ButtonContainer>
              <PrimaryLink to={`/member/venue/${activeMarker.venueId}`} disabled={buttonsDisabled} data-id={activeMarker.venueId} onClick={handleViewOffers}>View Offers</PrimaryLink>
              <OverlayCloseButton onClick={closeOverlay} disabled={buttonsDisabled}>Close</OverlayCloseButton>
            </ButtonContainer>
          </>
          :
          <>
            <span>Faild to load venue data</span>
            <OverlayCloseButton onClick={closeOverlay}>Close</OverlayCloseButton>
          </>
        }
      </InfoOverlay > */}
    </Wrapper>
  );
};
