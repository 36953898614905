import React, { useEffect, useState } from "react";
import { ClientOfferManager, ClientTable } from "./";
import { Route, useRouteMatch, Link, Switch } from "react-router-dom";
import styled from "styled-components";
import logo from "../../assets/admin_gs_logo.png";
import { colors } from "../../colors";
import { AppMain, AppWrapper, navHeight } from "../shared";
import { MdMenu, MdClose } from "react-icons/md";
import { Loader } from "../loaders";
import { useQuery } from "@apollo/react-hooks";
import { GET_USER_FROM_COOKIE } from "../";
import { OfferDisplay, ClientRouter } from "./";
import { getCookie, handleLogout } from "../../utility";
import { Copyright } from "../copyright";

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  background-color: ${colors.secondary};
  color: ${colors.secondaryOffset};
  height: ${navHeight};
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-width: 1850px;
  margin: auto;
  z-index: 10;
`;

const Logo = styled.img`
  padding: 2.5px 10px;
  height: 65px;
`;

const List = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;

  @media (max-width: 650px) {
    display: none;
  }
`;

const ListItem = styled.li``;

const StyledLink = styled(Link)`
  padding: 5px 10px;
  color: ${colors.secondaryOffset};
  text-decoration: none;
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: font-size 0.5s;
  flex-wrap: wrap;

  &:hover {
    font-size: 1.1rem;
    text-decoration: underline;
  }
`;

const BrewSaverLinks = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
`;

const BrewSaverLink = styled.a`
  color: ${colors.darkFont};
  margin: 0px 15px;
`;

export const ClientLanding = ({ match }) => {
  const { loading, error, data } = useQuery(GET_USER_FROM_COOKIE, {
    variables: { cookie: getCookie("uuid") },
    fetchPolicy: "no-cache"
  });
  const [authenticated, setAuth] = useState(false);
  let { path, url } = useRouteMatch();

  const [navLinks, setNavLinks] = useState([
    <StyledLink key="offer" to={`${url}/table/redemption`} style={{ fontSize: "20px", fontWeight: "bold" }}>
      Client Admin
    </StyledLink>
  ]);

  useEffect(() => {
    setNavLinks([
      <StyledLink key="offer" to={`${url}/table/redemption`} style={{ fontSize: "20px", fontWeight: "bold" }}>
        Client Admin
      </StyledLink>
    ]);
  }, [url]);

  useEffect(() => {
    if (data) {
      if (data.userFromCookie?.userType === "admin" || data.userFromCookie?.userType === "client") {
        setAuth(true);
      }
    }
  }, [data, setAuth]);

  if (loading) {
    return <Loader />;
  }

  if (data && !authenticated) {
    return (
      <div>
        not authenticated
        <br />
        <a href="/">login here</a>
      </div>
    );
  }

  return (
    <AppWrapper>
      <OfferDisplay />
      <AppMain>
        <Nav>
          <Logo src={logo} />
          <List style={{ display: "flex", listStyle: "none" }}>
            <ListItem>{navLinks.map((link) => link)}</ListItem>
          </List>
          <StyledLink onClick={handleLogout} to={"/"}>
            Logout
          </StyledLink>
        </Nav>
        <Switch>
          <Route path={`${path}/manage/:entity/:identifier?`} component={ClientRouter} />
          <Route default component={ClientTable} />
        </Switch>
        <BrewSaverLinks>
          <BrewSaverLink href="mailto: info@brewsaver.net">info@brewsaver.net</BrewSaverLink>
          <BrewSaverLink href="https://www.brewsaver.net" target="brewsavernet">
            www.brewsaver.net
          </BrewSaverLink>
        </BrewSaverLinks>
        <Copyright color="black" />
      </AppMain>
    </AppWrapper>
  );
};
export default ClientLanding;
