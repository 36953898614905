import gql from "graphql-tag";
import React, { useCallback, useState } from "react";
import { Button, Section, TextInput } from "../../shared";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { ToastContainer, toast } from "react-toastify";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";

const GET_SCORECARDS = gql`
  query ($userId: ID!) {
    usersScorecards(userId: $userId) {
      scorecardId
      userId
      title
      scores
      createdOn
      players
    }
  }
`;

const CREATE_SCORECARD = gql`
  mutation (
    $userId: ID!
    $createdOn: String!
    $scores: String!
    $title: String!
    $players: String!
    $games: String!
  ) {
    createScorecard(
      userId: $userId
      createdOn: $createdOn
      scores: $scores
      title: $title
      players: $players
      games: $games
    ) {
      scorecard {
        scorecardId
        userId
        createdOn
        scores
        title
        players
        games
      }
    }
  }
`;

const DELETE_SCORECARD = gql`
  mutation ($scorecardId: ID!) {
    deleteScorecard(scorecardId: $scorecardId) {
      success
    }
  }
`;

const MenuGrid = styled(Section)`
  display: flex;
  flex-direction: column;
  grid-template-columns: 1fr 1fr;
  column-gap: 0.5rem;
  row-gap: 1rem;
`;

const MenuHeader = styled.h1`
  grid-column: span 2;
  margin: 0;
`;

const CreateInput = styled(TextInput)`
  width: 100%;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
`;

const CardItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 0.5rem;
`;

const defaultScores = {
  1: { value: 0 },
  2: { value: 0 },
  3: { value: 0 },
  4: { value: 0 },
  5: { value: 0 },
  6: { value: 0 },
  7: { value: 0 },
  8: { value: 0 },
  9: { value: 0 },
  10: { value: 0 },
  11: { value: 0 },
  12: { value: 0 },
  13: { value: 0 },
  14: { value: 0 },
  15: { value: 0 },
  16: { value: 0 },
  17: { value: 0 },
  18: { value: 0 }
};

const createScores = (players) => {
  const scores = {};
  if (players["player1"]) {
    scores[players["player1"]] = defaultScores;
  }
  if (players["player2"]) {
    scores[players["player2"]] = defaultScores;
  }
  if (players["player3"]) {
    scores[players["player3"]] = defaultScores;
  }
  if (players["player4"]) {
    scores[players["player4"]] = defaultScores;
  }
  return scores;
};

const defaultGames = {
  game1: {},
  game2: {}
};

const createGames = (players) => {
  const games = {};
  if (players["player1"]) {
    games[players["player1"]] = defaultGames;
  }
  if (players["player2"]) {
    games[players["player2"]] = defaultGames;
  }
  if (players["player3"]) {
    games[players["player3"]] = defaultGames;
  }
  if (players["player4"]) {
    games[players["player4"]] = defaultGames;
  }
  return games;
};

export const ScorecardMenu = ({ userId }) => {
  const scorecardsQuery = useQuery(GET_SCORECARDS, {
    fetchPolicy: "no-cache",
    variables: { userId }
  });
  const [createScorecard] = useMutation(CREATE_SCORECARD);
  const [deleteScorecard] = useMutation(DELETE_SCORECARD);
  const [title, setTitle] = useState("");
  const [players, setPlayers] = useState({});

  const handleTitleChange = useCallback((e) => {
    setTitle(e.target.value);
  }, []);

  const handlePlayerChange = useCallback(
    (player) => (e) => {
      if (e.target.value) {
        setPlayers({ ...players, [player]: e.target.value });
      } else {
        const p = { ...players };
        delete p[player];
        setPlayers(p);
      }
    },
    [players]
  );

  const handleNewCard = useCallback(() => {
    if (title.length > 0 && !!players["player1"]) {
      createScorecard({
        variables: {
          userId,
          createdOn: new Date().toString(),
          title,
          scores: JSON.stringify(createScores(players)),
          players: JSON.stringify(players),
          games: JSON.stringify(createGames(players))
        }
      }).then(
        () => {
          toast.success("Scorecard has been created.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
          scorecardsQuery.refetch();
        },
        () => {
          toast.error("Something went wrong.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }
      );
    } else {
      toast.error("Title and Player One are required for a new scorecard.");
    }
  }, [createScorecard, scorecardsQuery, title, userId, players]);

  const handleDelete = useCallback(
    (scorecardId) => () => {
      deleteScorecard({
        variables: { scorecardId }
      }).then(
        () => {
          toast.success("Scorecard has been deleted.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
          scorecardsQuery.refetch();
        },
        () => {
          toast.error("Something went wrong.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }
      );
    },
    [deleteScorecard, scorecardsQuery]
  );

  return (
    <>
      <MenuGrid>
        <MenuHeader>Create Scorecard</MenuHeader>
        <InputWrapper>
          <CreateInput value={title} onChange={handleTitleChange} placeholder="Scorecard Title (Required)" />
          <CreateInput
            value={players["player1"]}
            onChange={handlePlayerChange("player1")}
            placeholder="Player One (Required)"
          />
          <CreateInput
            value={players["player2"]}
            onChange={handlePlayerChange("player2")}
            placeholder="Player Two (Optional)"
          />
          <CreateInput
            value={players["player3"]}
            onChange={handlePlayerChange("player3")}
            placeholder="Player Three (Optional)"
          />
          <CreateInput
            value={players["player4"]}
            onChange={handlePlayerChange("player4")}
            placeholder="Player Four (Optional)"
          />
          <Button onClick={handleNewCard}>Create New Card</Button>
        </InputWrapper>
      </MenuGrid>
      <MenuGrid>
        <MenuHeader>Scorecard History</MenuHeader>
        {scorecardsQuery.data?.usersScorecards
          ?.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
          .map((card) => {
            return (
              <CardItem key={card.scorecardId}>
                <div>
                  <span>{moment(card.createdOn).format("MM/DD/YY")} - </span>
                  <Link to={`/member/scorecard/${card.scorecardId}`}>{card.title}</Link>
                </div>
                <Button onClick={handleDelete(card.scorecardId)}>Delete</Button>
              </CardItem>
            );
          })}
      </MenuGrid>
      <ToastContainer />
    </>
  );
};
