export const colors = {
  primary: "#f0a500",
  primaryOffset: "white",
  primaryTransparent: "rgba(255, 120, 0, .8)",

  secondary: "#277200",
  secondaryTransparent: "rgba(0, 55, 0, .8)",
  secondaryOffset: "white",
  secondaryShaded: "#1e5800",
  headerIcon: "#277200",

  tableHeaderBg: "#277200",
  tableHeaderFont: "#fff",
  tableOdd: "#f6fff3",
  tableEven: "#ffffff",
  tableIcon: "#fff",
  tableIconBg: "#f0a500",

  defaultBg: "#fdfffd",
  defaultBgOffset: "black",
  memberBg: "#277200",

  navBg: "white",
  navLinkBg: "#277200",
  navLinkFont: "white",
  memberIconContainerBg: "rgba(255, 255, 255, .5)",

  success: "green",
  danger: "red",

  disabled: "#aaaaaa",

  tableLink: "#3580f1",
  linkColor: "#3580f1",
  inputBorder: "#cccccc",
  required: "#f0a500",
  darkFont: "#333333",

  totalStats: "#c08502",
  primaryChange: "#c08502",

  goodMessage: "#3ad733",
  badMessage: "red",
  middleMessage: "#f0a500",

  goodBackground: "#37c231"
};
