import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../colors";
import { MdFileUpload } from "react-icons/md";
import { iconSize, Button } from "../shared";
import { MdClose } from "react-icons/md";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FileInput = styled.input.attrs({ type: "file" })`
  visibility: hidden;
  height: 0;
  width: 0;
`;

const Image = styled.img`
  height: 50px;
`;

const ResetWrapper = styled.button`
  background: none;
  border: none;
`;

export const ImageUpload = ({ id, name, current, onChange }) => {
  const [file, setFile] = useState("");
  useEffect(() => {
    if (onChange) {
      onChange(name, file);
    }
  }, [name, file]);

  const openFileDialog = useCallback(() => {
    document.getElementById(id).click();
  }, [id]);

  const resetImage = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      setFile("");
      if (onChange) {
        onChange(name, "");
      }
    },
    [setFile]
  );

  const handleUpload = (e) => {
    if (e.currentTarget.files.length > 0) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const upload = fileReader.result;
        fetch(process.env.REACT_APP_UPLOAD_ENDPOINT, {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json"
          },
          redirect: "follow",
          referrer: "no-referrer",
          body: JSON.stringify({ photo: upload })
        }).then((response) => {
          response.json().then((json) => setFile(json.file));
        });
      };
      fileReader.readAsDataURL(e.currentTarget.files[0]);
    }
  };
  return (
    <Wrapper>
      <Button bgColor={colors.secondary} color={colors.secondaryOffset} onClick={openFileDialog}>
        <MdFileUpload size={iconSize} />
        Upload
      </Button>
      <Image src={(file || current) && `${process.env.REACT_APP_IMAGE_ENDPOINT}/${file || current}`} />
      <FileInput id={id} name={name} onChange={handleUpload} />
      {(file || current) && (
        <ResetWrapper type="button" onClick={resetImage}>
          <MdClose size={"30px"} style={{ zIndex: 1 }} />
        </ResetWrapper>
      )}
    </Wrapper>
  );
};
