import { toast } from "react-toastify";
import { colors } from "./colors";

export const getCookie = (cname) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const isFutureDate = (date) => {
  if (new Date() < date) {
    return true;
  }
  return false;
};

export const isPastDate = (date) => {
  if (new Date() > date) {
    return true;
  }
  return false;
};

const isDayValid = (validDays) => {
  if (validDays.includes(new Date().getDay())) {
    return true;
  }
  return false;
};

const getDateTimeFromStr = (timeStr) => {
  const date = new Date();
  const timeSplit = timeStr.split(":");
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), timeSplit[0], timeSplit[1]);
};

const isTimeValid = (startTime, endTime) => {
  if (!startTime && !endTime) {
    return true;
  }
  const date = new Date();
  if (!startTime) {
    const end = getDateTimeFromStr(endTime);
    if (date < end) {
      return true;
    }
  }
  if (!endTime) {
    const start = getDateTimeFromStr(startTime);
    if (start < date) {
      return true;
    }
  }
  const start = getDateTimeFromStr(startTime);
  const end = getDateTimeFromStr(endTime);
  if (start < date && date < end) {
    return true;
  }
  return false;
};

const isDayUseAvailable = (redemptions, usesPerDay) => {
  if (usesPerDay !== 0 && !usesPerDay) {
    return true;
  }
  const filtered = redemptions.filter((redemption) => {
    const today = new Date();
    const tomorrow = new Date(new Date().setDate(today.getDate() + 1));
    today.setHours(3, 0, 0, 0);
    tomorrow.setHours(3, 0, 0, 0);
    const date = new Date(`${redemption.date} ${redemption.time}`);
    if (today <= date && date < tomorrow) {
      return true;
    }
    return false;
  });
  return filtered.length < usesPerDay;
};

const isPeriodUseAvailable = (redemptions, redemptionPeriod, allowedRedemptions, resetDay) => {
  if (resetDay) {
    resetDay = JSON.parse(resetDay)?.value;
  }
  switch (redemptionPeriod) {
    case "perDay": {
      return isDayUseAvailable(redemptions, allowedRedemptions);
    }
    case "perWeek": {
      const filtered = redemptions.filter((redemption) => {
        const date = new Date(`${redemption.date} ${redemption.time}`);
        const lastReset = new Date();
        // set last reset @ 12:00 am
        const today = lastReset.getDay();
        if (today > resetDay) {
          lastReset.setDate(resetDay - today);
        } else if (today < resetDay) {
          lastReset.setDate(7 - (resetDay - today));
        }
        lastReset.setHours(3);
        lastReset.setMinutes(0);
        lastReset.setSeconds(0);
        lastReset.setMilliseconds(0);
        return date >= lastReset;
      });
      return filtered.length < allowedRedemptions;
    }
    case "perMonth": {
      const filtered = redemptions.filter((redemption) => {
        const date = new Date(`${redemption.date} ${redemption.time}`);
        const resetDate = new Date();
        // set reset to the 1st of the current month
        resetDate.setDate(1);
        resetDate.setHours(3);
        resetDate.setMinutes(0);
        resetDate.setSeconds(0);
        resetDate.setMilliseconds(0);
        return date >= resetDate;
      });
      return filtered.length < allowedRedemptions;
    }
    case "perYear": {
      const filtered = redemptions.filter((redemption) => {
        const date = new Date(`${redemption.date} ${redemption.time}`);
        const resetDate = new Date();
        // set reset to Jan 1st of this year
        resetDate.setMonth(1);
        resetDate.setDate(1);
        resetDate.setHours(3);
        resetDate.setMinutes(0);
        resetDate.setSeconds(0);
        resetDate.setMilliseconds(0);
        return date >= resetDate;
      });
      return filtered.length < allowedRedemptions;
    }
    default:
      return false;
  }
};

export const getNumRedeemedAfterReset = (redemptions, redemptionPeriod, allowedRedemptions, resetDay) => {
  if (resetDay) {
    resetDay = JSON.parse(resetDay)?.value;
  }
  switch (redemptionPeriod) {
    case "perDay": {
      const filtered = redemptions.filter((redemption) => {
        const today = new Date();
        const tomorrow = new Date(new Date().setDate(today.getDate() + 1));
        today.setHours(3, 0, 0, 0);
        tomorrow.setHours(3, 0, 0, 0);
        const date = new Date(`${redemption.date} ${redemption.time}`);
        if (today <= date && date < tomorrow) {
          return true;
        }
        return false;
      });
      return filtered.length;
    }
    case "perWeek": {
      const filtered = redemptions.filter((redemption) => {
        const date = new Date(`${redemption.date} ${redemption.time}`);
        const lastReset = new Date();
        // set last reset @ 12:00 am
        const today = lastReset.getDay();
        if (today > resetDay) {
          lastReset.setDate(resetDay - today);
        } else if (today < resetDay) {
          lastReset.setDate(7 - (resetDay - today));
        }
        lastReset.setHours(3);
        lastReset.setMinutes(0);
        lastReset.setSeconds(0);
        lastReset.setMilliseconds(0);
        return date >= lastReset;
      });
      return filtered.length;
    }
    case "perMonth": {
      const filtered = redemptions.filter((redemption) => {
        const date = new Date(`${redemption.date} ${redemption.time}`);
        const resetDate = new Date();
        // set reset to the 1st of the current month
        resetDate.setDate(1);
        resetDate.setHours(3);
        resetDate.setMinutes(0);
        resetDate.setSeconds(0);
        resetDate.setMilliseconds(0);
        return date >= resetDate;
      });
      return filtered.length;
    }
    case "perYear": {
      const filtered = redemptions.filter((redemption) => {
        const date = new Date(`${redemption.date} ${redemption.time}`);
        const resetDate = new Date();
        // set reset to Jan 1st of this year
        resetDate.setMonth(1);
        resetDate.setDate(1);
        resetDate.setHours(3);
        resetDate.setMinutes(0);
        resetDate.setSeconds(0);
        resetDate.setMilliseconds(0);
        return date >= resetDate;
      });
      return filtered.length;
    }
    default:
      return false;
  }
};

export const getOfferValidationMessage = (data) => {
  const {
    allowedRedemptions,
    usesPerDay,
    resetDay,
    resetTime,
    startDate,
    endDate,
    timeValidStart,
    timeValidEnd,
    redemptions
  } = data;
  const redemptionPeriod = JSON.parse(data?.redemptionPeriod)?.value;
  const validDays = data.validDays ? data.validDays.map((day) => parseInt(day)) : [];
  redemptions.sort((a, b) => new Date(b.date) - new Date(a.date));
  if (isFutureDate(new Date(startDate))) {
    // start date is in the future
    return { key: "Not Yet", message: "Time, Day or Date Restriction", color: colors.middleMessage };
  }

  const numRedeemed = getNumRedeemedAfterReset(redemptions, redemptionPeriod, allowedRedemptions, resetDay);
  if (numRedeemed >= allowedRedemptions) {
    const end = new Date(endDate);
    if (getResetDate(data) >= end) {
      return { key: "Redeemed", message: "Redeemed", color: colors.middleMessage };
    }
  }

  if (isPastDate(new Date(endDate))) {
    // end date is in the past
    return { key: "Expired", message: "Expired", color: colors.badMessage };
  }

  if (!isDayValid(validDays)) {
    // not a valid date to use the offer
    return { key: "Not Yet", message: getResetString(data), color: colors.middleMessage };
  }

  if (!isTimeValid(timeValidStart, timeValidEnd)) {
    // not a valid time to use the offer
    return { key: "Not Yet", message: getResetString(data), color: colors.middleMessage };
  }

  if (!isDayUseAvailable(redemptions, usesPerDay)) {
    // maximum uses reached for the day
    return { key: "Not Yet", message: getResetString(data), color: colors.middleMessage };
  }

  if (!isPeriodUseAvailable(redemptions, redemptionPeriod, allowedRedemptions, resetDay)) {
    // maximum uses reached for the period
    return { key: "Not Yet", message: getResetString(data), color: colors.middleMessage };
  }
  return { key: "Now Available", message: "Ready to Play", color: colors.goodMessage };
};

export const getZeroRedemptionOfferValidationMessage = (data) => {
  const { startDate, endDate, timeValidStart, timeValidEnd } = data;
  const validDays = data.validDays ? data.validDays.map((day) => parseInt(day)) : [];
  if (isFutureDate(new Date(startDate))) {
    // start date is in the future
    return { key: "Not Yet", message: getExpirationString(startDate) };
  }

  if (isPastDate(new Date(endDate))) {
    // end date is in the past
    return { key: "Expired", message: "Expired" };
  }

  if (!isDayValid(validDays)) {
    // not a valid date to use the offer
    return { key: "Not Yet", message: "Invalid Day" };
  }

  if (!isTimeValid(timeValidStart, timeValidEnd)) {
    // not a valid time to use the offer
    return { key: "Not Yet", message: "Invalid Time" };
  }
  return { key: "Now Available", message: "Now Available" };
};

export const getExpirationString = (endDateString) => {
  const endDate = new Date(endDateString);
  const difference = endDate.getTime() - new Date().getTime();
  if (difference <= 0) {
    return "Expired";
  } else {
    const numDays = Math.floor(difference / (1000 * 60 * 60 * 24));
    const numHours = Math.ceil(difference / (1000 * 60 * 60)) - numDays * 24;
    return `Expires in ${numDays} ${numDays !== 1 ? "Days" : "Day"}, ${numHours} ${numHours !== 1 ? "Hours" : "Hour"
      }`;
  }
};

const getDayHourBetweenDateString = (startDate, endDate) => {
  const difference = endDate.getTime() - startDate.getTime();
  const numDays = Math.floor(difference / (1000 * 60 * 60 * 24));
  const numHours = Math.ceil(difference / (1000 * 60 * 60)) - numDays * 24;
  if (numDays > 0 && numHours > 0) {
    return `Play Again in ${numDays} ${numDays !== 1 ? "Days" : "Day"}, ${numHours} ${numHours !== 1 ? "Hours" : "Hour"
      } `;
  } else if (numDays > 0) {
    return `Play Again in ${numDays} ${numDays !== 1 ? "Days" : "Day"} `;
  } else if (numHours > 0) {
    return `Play Again in ${numHours} ${numHours !== 1 ? "Hours" : "Hour"} `;
  }
  return "Time, Day or Date Restriction";
};

const getResetDate = (data) => {
  const { resetDay } = data;
  const redemptionPeriod = JSON.parse(data?.redemptionPeriod)?.value;
  const today = new Date();
  switch (redemptionPeriod) {
    case "perDay": {
      const resetDate = new Date();
      resetDate.setDate(resetDate.getDate() + 1);
      resetDate.setHours(3);
      resetDate.setMinutes(0);
      resetDate.setSeconds(0);
      resetDate.setMilliseconds(0);
      return resetDate;
    }
    case "perWeek": {
      const resetDate = new Date();
      let nextResetDay = 1;
      if (today.getDay() === nextResetDay) {
        resetDate.setDate(resetDate.getDate() + 7);
      } else {
        resetDate.setDate(resetDate.getDate() + ((7 + nextResetDay - today.getDay()) % 7));
      }
      resetDate.setHours(3);
      resetDate.setMinutes(0);
      resetDate.setSeconds(0);
      resetDate.setMilliseconds(0);
      return resetDate;
    }
    case "perMonth": {
      const resetDate = new Date();
      resetDate.setMonth(resetDate.getMonth() + 1);
      resetDate.setHours(3);
      resetDate.setMinutes(0);
      resetDate.setSeconds(0);
      resetDate.setMilliseconds(0);
      resetDate.setDate(1);
      return resetDate;
    }
    case "perYear": {
      const resetDate = new Date();
      resetDate.setYear(resetDate.getFullYear() + 1);
      resetDate.setHours(3);
      resetDate.setMinutes(0);
      resetDate.setSeconds(0);
      resetDate.setMilliseconds(0);
      resetDate.setMonth(0);
      resetDate.setDate(1);
      return resetDate;
    }
    default:
      return undefined;
  }
};

export const getResetString = (data) => {
  const { allowedRedemptions, usesPerDay, resetDay, timeValidStart, timeValidEnd, redemptions } = data;
  const redemptionPeriod = JSON.parse(data?.redemptionPeriod)?.value;
  const validDays = data.validDays ? data.validDays.map((day) => parseInt(day)) : [];
  const today = new Date();
  let message = "Time, Day or Date Restriction";
  const isAvailable = isPeriodUseAvailable(redemptions, redemptionPeriod, allowedRedemptions, resetDay);
  if (!isAvailable) {
    // maximum uses reached for the period
    const resetDate = getResetDate(data);
    return getDayHourBetweenDateString(new Date(), resetDate);
  }

  if (!isDayUseAvailable(redemptions, usesPerDay)) {
    // maximum uses reached for the day
    const resetDate = new Date();
    resetDate.setDate(resetDate.getDate() + 1);
    resetDate.setHours(3);
    resetDate.setMinutes(0);
    resetDate.setSeconds(0);
    resetDate.setMilliseconds(0);
    message = getDayHourBetweenDateString(new Date(), resetDate);
    return message;
  }

  if (!isTimeValid(timeValidStart, timeValidEnd)) {
    // not a valid time to use the offer
    return message;
  }

  if (!isDayValid(validDays)) {
    // not a valid date to use the offer
    return message;
  }
};

export const getMessage = (reject, defaultMessage) => {
  let message = reject.message;
  if (message.indexOf("email_1 dup key: { email:") !== -1) {
    message = "There is another brew user with that email.";
  } else {
    return defaultMessage || "A problem has occurred.";
  }
  return message;
};

export const handleReject = (reject, defaultMessage) => {
  const message = getMessage(reject, defaultMessage);
  toast.error(message, {
    position: "bottom-center",
    hideProgressBar: true,
    pauseOnHover: false,
    closeButton: false
  });
};

export const handleLogout = () => {
  fetch(`${process.env.REACT_APP_REST_ENDPOINT} /logout`, {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json"
    },
    redirect: "follow",
    referrer: "no-referrer",
    body: JSON.stringify({
      token: getCookie("uuid")
    })
  });
};
