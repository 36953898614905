import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors } from "../../colors";

const height = "40px";
const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: ${height};
  padding: 0 20px;
  box-sizing: border-box;
  border-left: 1px solid white;
  border-right: 1px solid white;
  background-color: ${({ backgroundColor }) => backgroundColor};
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};

  &:hover::after {
    visibility: visible;
  }
`;

// &::after {
//   content: "";
//   position: absolute;
//   width: ${({ scrollWidth }) => scrollWidth};
//   top: ${({ top }) => top};
//   left: 0;
//   height: ${height};
//   box-shadow: inset 0 0 5px rgba(255, 165, 0.5);
//   pointer-events: none;
//   visibility: hidden;
//   z-index: 1;
// }

const ChildrenWrapper = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: flex;
`;

export const CellLinkStyles = `
  cursor: pointer;
  align-self: center;
  text-decoration: none;
  color: ${colors.tableLink};
  text-shadow: 0px 0px 0.65px ${colors.tableLink}, 0px 0px 0.65px ${colors.tableLink},
    0px 0px 0.65px ${colors.tableLink};

  &:hover {
    text-shadow: none;
    text-decoration: underline;
  }

  &[disabled] {
    color: ${colors.disabled};
    text-shadow: none;
    text-decoration: none;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const CellLink = styled(Link)`
  ${CellLinkStyles}
`;

export const TableCell = ({
  children,
  title,
  text,
  backgroundColor,
  link,
  isJSX,
  handleClick,
  style,
  disabled,
  onMouseOver,
  onMouseOut
}) => {
  return (
    <Cell
      onClick={handleClick}
      backgroundColor={backgroundColor}
      style={style}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {children}
      {text !== undefined && (
        <ChildrenWrapper title={title}>
          {Array.isArray(link) ? (
            link.map((lk, idx) => (
              <CellLink
                style={{ marginRight: "4px" }}
                disabled={disabled}
                onClick={(e) => e.stopPropagation()}
                to={lk}
              >
                {`${text[idx]}${idx < text.length - 1 ? ", " : ""}`}
              </CellLink>
            ))
          ) : link && text !== "Deleted" ? (
            <CellLink disabled={disabled} onClick={(e) => e.stopPropagation()} to={link}>
              {text}
            </CellLink>
          ) : (
            text
          )}
        </ChildrenWrapper>
      )}
    </Cell>
  );
};
