import React, { useCallback, useState } from "react";
import { useClientContext } from ".";
import styled from "styled-components";
import { colors } from "../../colors";

const LinkText = styled.a`
  cursor: pointer;
  align-self: center;
  text-decoration: none;
  color: ${colors.tableLink};
  text-shadow: 0px 0px 0.65px ${colors.tableLink}, 0px 0px 0.65px ${colors.tableLink},
    0px 0px 0.65px ${colors.tableLink};

  &:hover {
    text-shadow: none;
    text-decoration: underline;
  }

  &[disabled] {
    color: ${colors.disabled};
    text-shadow: none;
    text-decoration: none;
    cursor: not-allowed;
    pointer-events: none;
  }
`;

export const OfferTypeCell = ({ offer }) => {
  const [, clientDispatch] = useClientContext();
  const [text] = useState(JSON.parse(offer.offerType)?.label);

  const handleClick = useCallback(() => {
    clientDispatch({
      type: "SET_OFFER",
      payload: { activeOffer: offer }
    });
  }, [clientDispatch]);

  return <LinkText onClick={handleClick}>{text}</LinkText>;
};
