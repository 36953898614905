import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { BaseInput } from "../../shared";
import { useParams } from "react-router";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
// import { PlayerScorecard } from "./player";
import { PlayerScorecard2 } from "./player2";

const GET_SCORECARD = gql`
  query ($userId: ID!, $scorecardId: ID!) {
    userScorecard(userId: $userId, scorecardId: $scorecardId) {
      scorecardId
      userId
      title
      scores
      createdOn
      games
      game1Name
      game1Value
      game2Name
      game2Value
    }
  }
`;

const UPDATE_SCORECARD = gql`
  mutation ($changes: ScorecardInput!, $scorecardId: ID!) {
    updateScorecard(changes: $changes, scorecardId: $scorecardId) {
      scorecard {
        scorecardId
        userId
        title
        scores
        createdOn
        games
        game1Name
        game1Value
        game2Name
        game2Value
      }
    }
  }
`;

const Title = styled.h1`
  margin: 0;
`;

const GameSetupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.95);
  margin-bottom: 0.5rem;
  align-items: center;
  grid-gap: 0.5rem;
  padding: 0.5rem;
`;

const ViewWrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
`;

const MoneyGameWrapper = styled.div`
  display: flex;
  grid-gap: 0.5rem;
  align-items: center;
`;

const PlayersWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto 14ch;
  width: 100%;
  overflow-x: scroll;
`;

const GameInput = styled(BaseInput)`
  min-width: unset;
  max-width: 150px;
`;

const MoneyInput = styled(BaseInput)`
  min-width: unset;
  max-width: 50px;
`;

export const MemberScorecard = ({ userId }) => {
  let { scorecardId } = useParams();
  const [moneyGameMap, setMoneyGameMap] = useState({});
  const scorecardQuery = useQuery(GET_SCORECARD, {
    fetchPolicy: "no-cache",
    variables: { userId, scorecardId }
  });

  const [updateScorecard] = useMutation(UPDATE_SCORECARD);

  const [scoreMap, setScoreMap] = useState({});

  useEffect(() => {
    if (!scorecardQuery.loading && !scorecardQuery.error) {
      if (scorecardQuery?.data?.userScorecard.scores) {
        setScoreMap(
          JSON.parse(
            scorecardQuery?.data?.userScorecard.scores
              .replaceAll("'", '"')
              .replaceAll("True", "true")
              .replaceAll("False", "false")
          )
        );
      }
      if (scorecardQuery?.data?.userScorecard.games) {
        setMoneyGameMap(
          JSON.parse(
            scorecardQuery?.data?.userScorecard.games
              .replaceAll("'", '"')
              .replaceAll("True", "true")
              .replaceAll("False", "false")
          )
        );
      }
      if (scorecardQuery?.data?.userScorecard.game1Name) {
        setGame1Name(scorecardQuery?.data?.userScorecard.game1Name);
      }
      if (scorecardQuery?.data?.userScorecard.game1Value) {
        setGame1Value(scorecardQuery?.data?.userScorecard.game1Value);
      }
      if (scorecardQuery?.data?.userScorecard.game2Name) {
        setGame2Name(scorecardQuery?.data?.userScorecard.game2Name);
      }
      if (scorecardQuery?.data?.userScorecard.game2Value) {
        setGame2Value(scorecardQuery?.data?.userScorecard.game2Value);
      }
    }
  }, [scorecardQuery?.data, scorecardQuery.error, scorecardQuery.loading]);

  const [game1Name, setGame1Name] = useState("");
  const [game2Name, setGame2Name] = useState("");

  const [game1Value, setGame1Value] = useState("");
  const [game2Value, setGame2Value] = useState("");

  const handleScoreChange = useCallback(
    (scoreChange) => {
      const newScores = { ...scoreMap, [scoreChange.playerName]: scoreChange.scores };
      const changes = { scores: newScores };
      setScoreMap(newScores);
      updateScorecard({
        variables: {
          changes,
          scorecardId: scorecardId
        }
      });
    },
    [scoreMap, scorecardId, updateScorecard]
  );

  const handleGameChange = useCallback(
    (gameChange) => {
      const newGames = { ...moneyGameMap, [gameChange.playerName]: gameChange.games };
      const changes = { games: newGames };
      setMoneyGameMap(newGames);
      updateScorecard({
        variables: {
          changes,
          scorecardId: scorecardId
        }
      });
    },
    [moneyGameMap, scorecardId, updateScorecard]
  );

  const handleGameConfigChange = useCallback(() => {
    updateScorecard({
      variables: {
        changes: { game1Name, game1Value, game2Name, game2Value },
        scorecardId: scorecardId
      }
    });
  }, [game1Name, game1Value, game2Name, game2Value, scorecardId, updateScorecard]);

  if (scorecardQuery.loading) {
    return <div>loading...</div>;
  }

  return (
    <ViewWrapper>
      <GameSetupWrapper>
        <Title>{scorecardQuery?.data?.userScorecard.title}</Title>
        <MoneyGameWrapper>
          <label>Game 1</label>
          <GameInput
            placeholder="Game 1 Name"
            value={game1Name}
            onChange={(e) => setGame1Name(e.target.value)}
            onBlur={handleGameConfigChange}
          />
          <label>$</label>
          <MoneyInput
            placeholder="0"
            value={game1Value}
            onChange={(e) => {
              setGame1Value(e.target.value);
            }}
            onBlur={handleGameConfigChange}
          />
        </MoneyGameWrapper>
        <MoneyGameWrapper>
          <label>Game 2</label>
          <GameInput
            placeholder="Game 2 Name"
            value={game2Name}
            onChange={(e) => setGame2Name(e.target.value)}
            onBlur={handleGameConfigChange}
          />
          <label>$</label>
          <MoneyInput
            placeholder="0"
            value={game2Value}
            onChange={(e) => setGame2Value(e.target.value)}
            onBlur={handleGameConfigChange}
          />
        </MoneyGameWrapper>
      </GameSetupWrapper>
      <PlayersWrapper>
        {Object.entries(scoreMap).map(([playerName, scores], index) => {
          return (
            <PlayerScorecard2
              key={`${index}`}
              numSetsOfNine={2}
              playerName={playerName}
              scores={scores}
              playerGame={moneyGameMap[playerName]}
              onScoreChange={handleScoreChange}
              onMoneyGameChange={handleGameChange}
              game1Name={game1Name || "Game 1"}
              game2Name={game2Name || "Game 2"}
              game1Value={game1Value}
              game2Value={game2Value}
            />
          );
        })}
      </PlayersWrapper>
    </ViewWrapper>
  );
};
