import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import logo from "../../logo.svg";

const LoaderWrapper = styled.div`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  position: relative;
  display: flex;
  align-items: flex-end;
`;

const GolfBounceAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  20% {
    opacity: 1;
    transform: translate(30px, -25px);
  }
  40% {
    transform: translate(60px, 0);
  }
  58% {
    transform: translate(80px, -20px);
  }
  76% {
    transform: translate(100px, 0);
  }
  88% {
    transform: translate(110px, -10px);
  }
  100% {
    transform: translate(120px, 0);
  }

`;

const GolfBall = styled.div`
  position: absolute;
  border-radius: 50%;
  border: ${({ size }) => size}px solid #ffffff;
  height: 0;
  width: 0;
  animation: ${GolfBounceAnimation} 3s infinite;
  animation-timing-function: linear;
`;

const Flag = styled.div``;

const LoadingText = styled.div`
  position: absolute;
`;

export const GolfLoader = ({ height }) => {
  const [active, setActive] = React.useState(false);
  const width = height * 2;

  useEffect(() => {
    setActive(true);
  }, [setActive]);

  return (
    <LoaderWrapper height={height} width={width}>
      <GolfBall size={height * 0.075} />
    </LoaderWrapper>
  );
};
