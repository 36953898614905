import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../colors";
import { CheckboxColumn } from ".";
import { useTableViewModelContext } from "./tableContext";
import { useFilterViewModelContext } from "./filterContext";
import { TextInput } from "../shared";
import { MdRefresh } from "react-icons/md";

const FilterRefresh = styled(MdRefresh)`
  color: ${colors.secondaryOffset};
`;

const FilterInput = styled(TextInput)`
  width: 100%;
  font-size: 12px;
  height: 24px;
`;

const height = "40px";
export const FilterColumn = styled.div`
  position: sticky;
  top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  height: ${height};
  box-sizing: border-box;
  border-left: 1px solid ${colors.tableHeaderFont};
  border-right: 1px solid ${colors.tableHeaderFont};
  background-color: ${colors.tableHeaderBg};
  color: ${colors.tableHeaderFont};
  cursor: pointer;
`;

export const TableFilter = ({ table, isSelectable, filter }) => {
  const [tableData, tableDispatch] = useTableViewModelContext();
  const [filterData, filterDispatch] = useFilterViewModelContext();

  useEffect(() => {
    if (filter.name && filter.value) {
      filterDispatch({
        type: "SET_VALUE",
        payload: { table, name: filter.name, value: filter.value }
      });
    }
  }, [filterDispatch, filter]);

  const handleRefresh = useCallback(() => {
    filterDispatch({
      type: "RESET_FILTERS",
      payload: table
    });
    tableDispatch({
      type: "RESET_DATA",
      payload: table
    });
  }, [filterDispatch]);

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      filterDispatch({
        type: "SET_VALUE",
        payload: { table, name, value }
      });
    },
    [filterDispatch]
  );

  const handleFilter = useCallback((e) => {
    if (e.key === "Enter" || e.key === undefined)
      tableDispatch({
        type: "FILTER_DATA",
        payload: { table, filters: filterData[table].values }
      });
  });

  if (filterData[table].isVisible) {
    return (
      <>
        {isSelectable && (
          <CheckboxColumn
            sticky={"40px"}
            key={`${table}_cell_checkbox_header`}
            backgroundColor={colors.tableHeaderBg}
          >
            <FilterRefresh size={"30px"} onClick={handleRefresh} />
          </CheckboxColumn>
        )}
        {tableData[table].columns.map((column) => (
          <FilterColumn key={`${table}_header_${column.header}`}>
            <FilterInput
              name={column.header}
              placeholder={column.header}
              onBlur={handleFilter}
              onKeyPress={handleFilter}
              onChange={handleChange}
              value={filterData[table].values[column.header]}
            />
          </FilterColumn>
        ))}
      </>
    );
  }

  return <></>;
};
