import React, { useState, useEffect } from "react";
import { Table } from "../";
import { MdAdd } from "react-icons/md";
import { useParams } from "react-router-dom";

const buttonSize = "2em";
export const ClientTable = () => {
  let { filterName, filterValue } = useParams();
  const [filter, setFilter] = useState({ name: filterName, value: filterValue });

  useEffect(() => {
    setFilter({ name: filterName, value: filterValue });
  }, [filterName, filterValue]);

  const buttons = [
    // {
    //   icon: <MdAdd size={buttonSize} />,
    //   text: "Add Offer",
    //   link: "/client/manage/offer"
    // }
  ];

  // TODO all table strings should come from one variable
  const table = "clientRedemption";
  return <Table table={table} buttons={buttons} areRowsSelectable={false} filter={filter} />;
};
