import React, { useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Table } from "../";
import { MdAdd, MdFilterList, MdAutorenew, MdCheck, MdClose, MdEmail, MdRemove } from "react-icons/md";
import {
  useTableViewModelContext,
  useSelectViewModelContext,
  useFilterViewModelContext,
  TableButton,
  SelectButton
} from "../table";
import { toast } from "react-toastify";

import gql from "graphql-tag";
import { Button, ConfirmText, Modal, ConfirmButtonWrapper, ModalWrapper } from "../shared";
import { useMutation } from "@apollo/react-hooks";

const CLEAR_REDEMPTIONS = gql`
  mutation($ids: [ID]!, $item: String!) {
    clearRedemptions(ids: $ids, item: $item) {
      success
    }
  }
`;

const ACTIVATE_USERS = gql`
  mutation($ids: [ID]!) {
    activateUsers(ids: $ids) {
      success
    }
  }
`;

const DEACTIVATE_USERS = gql`
  mutation($ids: [ID]!) {
    deactivateUsers(ids: $ids) {
      success
    }
  }
`;

const DELETE_MUTATIONS = {
  member: gql`
    mutation($ids: [ID]!) {
      deleteUsers(ids: $ids) {
        success
      }
    }
  `,
  client: gql`
    mutation($ids: [ID]!) {
      deleteUsers(ids: $ids) {
        success
      }
    }
  `,
  venue: gql`
    mutation($ids: [ID]!) {
      deleteVenues(ids: $ids) {
        success
      }
    }
  `,
  offer: gql`
    mutation($ids: [ID]!) {
      deleteOffers(ids: $ids) {
        success
      }
    }
  `,
  redemption: gql`
    mutation($ids: [ID]!) {
      deleteRedemptions(ids: $ids) {
        success
      }
    }
  `
};

const LinkButton = ({ table, text, icon, link }) => {
  return <TableButton key={`${table}_ADDMEMBER`} text={text} icon={icon} link={link} />;
};

const DeleteButton = ({ table }) => {
  const [selectData, selectDispatch] = useSelectViewModelContext();
  const [tableData, tableDispatch] = useTableViewModelContext();
  const [deleteEntries] = useMutation(DELETE_MUTATIONS[table]);
  const [isModalVisible, setModalVisible] = useState(false);

  const showModal = useCallback(() => {
    setModalVisible(true);
  }, [setModalVisible]);

  const hideModal = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  const handleDelete = useCallback(() => {
    const ids = selectData[table].filter((item) => item !== -1);
    deleteEntries({
      variables: {
        ids: ids
      }
    }).then(
      () => {
        toast.success(`Successfully deleted`, {
          position: "bottom-center",
          hideProgressBar: true,
          pauseOnHover: false,
          closeButton: false,
          autoClose: 2500
        });
      },
      () => {
        toast.error(`Something went wrong`, {
          position: "bottom-center",
          hideProgressBar: true,
          pauseOnHover: false,
          closeButton: false,
          autoClose: 2500
        });
      }
    );
    selectDispatch({
      type: "DELETE_SELECTED",
      payload: table
    });
    tableDispatch({
      type: "DELETE_IDS",
      payload: { table, ids }
    });
    setModalVisible(false);
  }, [selectData, tableData, table]);

  return (
    <>
      <TableButton
        key={`${table}_DELETEMEMBER`}
        text="Delete Member"
        icon={<MdRemove size={buttonSize} />}
        handleClick={showModal}
      />
      {isModalVisible && (
        <ModalWrapper>
          <Modal>
            <ConfirmText>
              Really? Delete {selectData[table].filter((rowId) => rowId !== -1).length} Record(s)
            </ConfirmText>
            <ConfirmButtonWrapper>
              <Button onClick={handleDelete}>Yes, Delete</Button>
              <Button onClick={hideModal}>Cancel</Button>
            </ConfirmButtonWrapper>
          </Modal>
        </ModalWrapper>
      )}
    </>
  );
};

const ActivateButton = ({ table }) => {
  const [selectData] = useSelectViewModelContext();
  const [, tableDispatch] = useTableViewModelContext();
  const [activateUsers] = useMutation(ACTIVATE_USERS);

  const handleActivateUser = useCallback(() => {
    const ids = selectData[table].filter((item) => item !== -1);
    activateUsers({
      variables: {
        ids
      }
    });
    tableDispatch({
      type: "SET_IDS_COLUMN",
      payload: { table, ids, column: "userStatus", value: "active" }
    });
  }, [selectData, table]);

  return (
    <TableButton
      key={`${table}_ACTIVATEMEMBER`}
      text="Activate Member"
      icon={<MdCheck size={buttonSize} />}
      handleClick={handleActivateUser}
    />
  );
};

const DeactivateButton = ({ table }) => {
  const [selectData] = useSelectViewModelContext();
  const [, tableDispatch] = useTableViewModelContext();
  const [deactivateUsers] = useMutation(DEACTIVATE_USERS);

  const handleDeactivateUser = useCallback(() => {
    const ids = selectData[table].filter((item) => item !== -1);
    deactivateUsers({
      variables: {
        ids
      }
    });
    tableDispatch({
      type: "SET_IDS_COLUMN",
      payload: { table, ids, column: "userStatus", value: "inactive" }
    });
  }, [selectData, table]);

  return (
    <TableButton
      key={`${table}_DEACTIVATEMEMBER`}
      text="Deactivate Member"
      icon={<MdClose size={buttonSize} />}
      handleClick={handleDeactivateUser}
    />
  );
};

const ClearButton = ({ table }) => {
  const [selectData] = useSelectViewModelContext();
  const [, tableDispatch] = useTableViewModelContext();
  const [clearRedemptions] = useMutation(CLEAR_REDEMPTIONS);

  const handleClearRedemption = useCallback(() => {
    const ids = selectData[table].filter((item) => item !== -1);
    clearRedemptions({
      variables: {
        ids,
        item: table
      }
    });
    tableDispatch({
      type: "SET_IDS_COLUMN",
      payload: { table, ids, column: "redemptions", value: [] }
    });
  }, [selectData, table]);

  return (
    <TableButton
      key={`${table}_CLEARREDEMPTIONS`}
      text="Clear Redemptions"
      icon={<MdAutorenew size={buttonSize} />}
      handleClick={handleClearRedemption}
    />
  );
};

const buttonSize = "2em";
export const AdminTable = ({ tableRoute }) => {
  let { table, filterName, filterValue } = useParams();
  if (tableRoute) {
    table = tableRoute; // probably shouldnt do this maybe use state
  }
  // const [selectData, selectDispatch] = useSelectViewModelContext();
  // const [tableData, tableDispatch] = useTableViewModelContext();
  // const [, filterDispatch] = useFilterViewModelContext();
  // const [deleteEntries] = useMutation(DELETE_MUTATIONS[table]);
  // const [clearRedemptions] = useMutation(CLEAR_REDEMPTIONS);
  // const [activateUsers] = useMutation(ACTIVATE_USERS);
  // const [deactivateUsers] = useMutation(DEACTIVATE_USERS);
  const [isModalVisible, setModalVisible] = useState(false);
  const [filter, setFilter] = useState({ name: filterName, value: filterValue });

  useEffect(() => {
    setFilter({ name: filterName, value: filterValue });
  }, [filterName, filterValue]);

  const buttons = {
    member: [
      <LinkButton
        table={table}
        icon={<MdAdd size={buttonSize} />}
        text="Add Member"
        link="/admin/manage/member"
      />,
      <DeleteButton table={table} />,
      <ActivateButton table={table} />,
      <DeactivateButton table={table} />,
      <ClearButton table={table} />,
    ],
    client: [
      <LinkButton
        table={table}
        icon={<MdAdd size={buttonSize} />}
        text="Add Client"
        link="/admin/manage/client"
      />,
      <DeleteButton table={table} />
    ],
    venue: [
      <LinkButton
        table={table}
        icon={<MdAdd size={buttonSize} />}
        text="Add Course"
        link="/admin/manage/venue"
      />,
      <DeleteButton table={table} />
    ],
    offer: [
      <LinkButton
        table={table}
        icon={<MdAdd size={buttonSize} />}
        text="Add Offer"
        link="/admin/manage/offer"
      />,
      <DeleteButton table={table} />
    ],
    redemption: [<DeleteButton table={table} />]
  };

  return (
    <>
      <Table
        table={table}
        buttons={buttons[table]}
        areRowsSelectable
        isImportVisible={table === "member" || table === "client"}
        isExportVisible={table === "member" || table === "client"}
        isSelectVisible={table === "member"}
        filter={filter}
      />
    </>
  );
};
