import React from "react";
import { BeerLoader } from ".";
import { colors } from "../../colors";
import { LoaderWrapper, LoaderBackground, LoaderText } from "../shared";
import spinningball from "../../assets/spinningball.gif";

export const Loader = ({ size, text }) => (
  <LoaderWrapper>
    <img src={spinningball} style={{ width: "55%", maxWidth: "200px" }} />
  </LoaderWrapper>
);
