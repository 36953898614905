import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { MdAdd, MdContentCopy, MdRemove, MdSave } from "react-icons/md";
import styled from "styled-components";
import { ImageUpload } from "../";
import { Loader } from "../loaders";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { handleReject } from "../../utility";
import { VenuePreview } from "../client/venuePreview";
import { useSelectViewModelContext, useTableViewModelContext } from "../table";
import {
  Label,
  ManagerButton,
  ManagerButtonText,
  MultipleInputWrapper,
  RadioInput,
  RadioLabel,
  Text,
  TextInput,
  Form,
  ModalWrapper,
  ManagerWrapper,
  ModalHeader,
  Modal,
  ConfirmText,
  ConfirmButtonWrapper,
  Button,
  StyledCreatableSelect,
  StyledSelect
} from "../shared";
import { colors } from "../../colors";

const GET_VENUE = gql`
  query($venueId: ID) {
    venue(venueId: $venueId) {
      status
      logo
      image
      name
      email
      website
      phone
      address
      city
      state
      zipCode
      notes
      created
      lastUpdate
      area
      clients {
        userId
        firstName
        lastName
      }
    }
  }
`;

const CREATE_VENUE = gql`
  mutation(
    $address: String
    $city: String
    $email: String
    $image: String
    $logo: String
    $name: String!
    $notes: String
    $phone: String
    $state: String
    $created: String!
    $status: String!
    $website: String
    $zipCode: String
    $area: String!
    $clients: [ID]
  ) {
    createVenue(
      address: $address
      city: $city
      email: $email
      image: $image
      logo: $logo
      name: $name
      notes: $notes
      phone: $phone
      state: $state
      status: $status
      created: $created
      website: $website
      zipCode: $zipCode
      area: $area
      clients: $clients
    ) {
      venue {
        venueId
        name
      }
    }
  }
`;

const DELETE_VENUES = gql`
  mutation($ids: [ID]!) {
    deleteVenues(ids: $ids) {
      success
    }
  }
`;

const UPDATE_VENUE = gql`
  mutation($changes: VenueInput!, $venueId: ID!) {
    updateVenue(changes: $changes, venueId: $venueId) {
      venue {
        name
        status
      }
    }
  }
`;

const GET_VENUE_AREAS = gql`
  query {
    allVenueAreas {
      venueAreaId
      text
    }
  }
`;

const CREATE_VENUE_AREA = gql`
  mutation($text: String!) {
    createVenueArea(text: $text) {
      venueAreaId
    }
  }
`;

const DELETE_VENUE_AREA = gql`
  mutation($venueAreaId: ID!) {
    deleteVenueArea(venueAreaId: $venueAreaId) {
      success
    }
  }
`;

const GET_CLIENTS = gql`
  query {
    clients {
      edges {
        node {
          userId
          firstName
          lastName
        }
      }
    }
  }
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

const LeftButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Header = styled.h2`
  margin: 0;
  padding: 0;
`;

const RightButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const OfferTypeList = styled.ul`
  list-decoration: none;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const OfferTypeSpan = styled.span``;

const OfferTypeItem = styled.li`
  display: flex;
  align-items: center;
  padding: 5px;
  justify-content: space-between;
`;

const AddOfferTypeWrapper = styled.div`
  display: flex;
  width: 90%;
  margin-top: 15px;
`;

const ManagerBtn = styled(Button)`
  width: 90%;
  margin-top: 5px;
`;

const iconSize = "1.5em";

const defaultState = {
  status: "",
  logo: "",
  image: "",
  name: "",
  email: "",
  website: "",
  phone: "",
  address: "",
  city: "",
  state: "",
  zipCode: "",
  notes: "",
  area: "",
  clients: [],
  isSubmitting: false,
  dirtyFields: [],
  isModalVisible: false,
  isDeleteVisible: false,
  isAreaManagerVisible: false,
  isPreviewVisible: false,
  newVenueArea: ""
};

const venueManagerDispatch = (state, action) => {
  switch (action.type) {
    case "SHOW_PREVIEW": {
      return {
        ...state,
        isPreviewVisibe: action.payload
      };
    }
    case "SET_IDENTIFIER": {
      return {
        ...state,
        identifier: action.payload
      };
    }
    case "SET_ALL": {
      return {
        ...action.payload
      };
    }
    case "SET_ONE": {
      const newState = { ...state };
      newState[action.payload.name] = action.payload.value;
      if (
        !newState.dirtyFields.includes(action.payload.name) &&
        newState[action.payload.name] !== state[action.payload.name] &&
        action.payload.name !== "newVenueArea"
      ) {
        newState.dirtyFields.push(action.payload.name);
      }
      return newState;
    }
    case "RESET_DIRTY": {
      return {
        ...state,
        dirtyFields: []
      };
    }
    case "SET_AREA_MANAGER_OPEN": {
      return {
        ...state,
        isAreaManagerVisible: action.payload
      };
    }
    case "START_SAVE": {
      return {
        ...state,
        isSubmitting: true
      };
    }
    case "STOP_SAVE": {
      return {
        ...state,
        isSubmitting: false,
        dirtyFields: []
      };
    }
    case "SHOW_MODAL": {
      return {
        ...state,
        isModalVisible: action.payload
      };
    }
    case "SHOW_DELETE_MODAL": {
      return {
        ...state,
        isDeleteVisible: action.payload
      };
    }
    case "ADD_VENUE": {
      return {
        ...defaultState,
        dirtyFields: []
      };
    }
    case "DUPLICATE_VENUE": {
      const dirtyFields = Object.keys(state)
        .map((key) => {
          if (state[key] !== defaultState[key]) {
            return key;
          }
        })
        .filter((item) => item !== undefined);
      return {
        ...state,
        identifier: undefined,
        dirtyFields
      };
    }
    default:
      return { ...state };
  }
};

export const VenueManager = ({ identifier, isLimited }) => {
  const [missingFields, setMissing] = useState([]);
  const [state, componentDispatch] = useReducer(
    venueManagerDispatch,
    Object.assign({}, defaultState, { identifier })
  );

  const { loading, error, data, refetch } = useQuery(GET_VENUE, {
    variables: { venueId: state.identifier },
    fetchPolicy: "no-cache"
  });
  const [createVenue] = useMutation(CREATE_VENUE);
  const [updateVenue] = useMutation(UPDATE_VENUE);
  const [deleteVenue] = useMutation(DELETE_VENUES);

  const [selectData, selectDispatch] = useSelectViewModelContext();
  const [tableData, tableDispatch] = useTableViewModelContext();

  const [createVenueArea] = useMutation(CREATE_VENUE_AREA);
  const [deleteVenueArea] = useMutation(DELETE_VENUE_AREA);
  const venueAreasQuery = useQuery(GET_VENUE_AREAS, {
    variables: {},
    fetchPolicy: "no-cache"
  });
  const getClients = useQuery(GET_CLIENTS, {
    variables: {},
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    if (state.identifier && data && data.venue) {
      const { venue } = data;
      let { area, clients } = venue;
      clients = clients.map((client) => ({
        value: client.userId,
        label: `${client.firstName} ${client.lastName}`
      }));
      venue.clients = clients;
      area = { value: area, label: area };
      venue.area = area;
      componentDispatch({
        type: "SET_ALL",
        payload: Object.assign({ ...state }, { ...venue })
      });
    }
  }, [state.identifier, data]);

  const hideModal = useCallback(() => {
    componentDispatch({
      type: "SHOW_MODAL",
      payload: false
    });
  }, [componentDispatch]);

  const showDelete = useCallback(() => {
    componentDispatch({
      type: "SHOW_DELETE_MODAL",
      payload: true
    });
  }, [componentDispatch]);

  const hideDelete = useCallback(() => {
    componentDispatch({
      type: "SHOW_DELETE_MODAL",
      payload: false
    });
  }, [componentDispatch]);

  const handleDelete = useCallback(() => {
    if (identifier) {
      const ids = [identifier];
      deleteVenue({
        variables: {
          ids: ids
        }
      }).then(
        () => {
          toast.success("Deleted successfully.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
          selectDispatch({
            type: "DELETE_IDS",
            payload: { table: "venue", ids: [identifier] }
          });
          tableDispatch({
            type: "DELETE_IDS",
            payload: { table: "venue", ids: [identifier] }
          });
          addVenue();
        },
        (reject) => handleReject(reject, "A problem occurred while deleting the venue.")
      );
    }
    hideDelete();
  }, [selectData, tableData, identifier]);

  const addVenue = useCallback(() => {
    componentDispatch({
      type: "ADD_VENUE"
    });
    toast.success("New venue is ready to be created.", {
      position: "bottom-center",
      hideProgressBar: true,
      pauseOnHover: false,
      closeButton: false
    });
  }, [componentDispatch]);

  const duplicateVenue = useCallback(() => {
    componentDispatch({
      type: "DUPLICATE_VENUE"
    });
    toast.success("Venue has been duplicated.", {
      position: "bottom-center",
      hideProgressBar: true,
      pauseOnHover: false,
      closeButton: false
    });
  }, [componentDispatch]);

  const validateFields = useCallback(
    (required) => {
      const invalid = [];
      required.forEach((field) => {
        const value = state[field];
        if (value === null || value === undefined) {
          invalid.push(field);
          return;
        }
        switch (typeof value) {
          case "object":
            if (Array.isArray(value) && value.length === 0) {
              invalid.push(field);
            } else {
              //object ? shouldnt get this..
            }
            break;
          case "string":
            if (value.length === 0) {
              invalid.push(field);
              break;
            }
            break;
          default:
            break;
        }
        if (value !== null && value !== undefined) {
          if (value) return;
        } else {
          invalid.push(field);
        }
      });
      return invalid;
    },
    [state]
  );

  const saveVenue = useCallback(() => {
    const requiredFields = ["status", "name", "area"];
    const translator = {
      status: "Status",
      name: "Name",
      area: "Area"
    };
    const invalidFields = validateFields(requiredFields);
    if (invalidFields.length > 0) {
      if (invalidFields.length === 1) {
        toast.error(`Missing field: ${translator[invalidFields[0]]}`, {
          position: "bottom-center",
          hideProgressBar: true,
          pauseOnHover: false,
          closeButton: false
        });
      } else {
        toast.error(`Missing fields: ${invalidFields.map((field) => translator[field]).join(", ")}`, {
          position: "bottom-center",
          hideProgressBar: true,
          pauseOnHover: false,
          closeButton: false
        });
      }
      setMissing(invalidFields);
      return;
    }

    const data = {
      status: state.status,
      logo: state.logo,
      image: state.image,
      name: state.name,
      email: state.email,
      website: state.website,
      phone: state.phone,
      address: state.address,
      city: state.city,
      state: state.state,
      zipCode: state.zipCode,
      notes: state.notes,
      created: state.created || new Date().toString(),
      area: state.area.value,
      clients: state.clients.map((client) => client.value)
    };
    if (state.identifier) {
      const changes = {};
      state.dirtyFields.map((field) => {
        switch (field) {
          case "area": {
            changes[field] = state[field].value;
            break;
          }
          case "clients": {
            changes[field] = state[field].map((client) => client.value);
            break;
          }
          default:
            changes[field] = state[field];
            break;
        }
      });
      changes["lastUpdate"] = new Date().toString();
      componentDispatch({ type: "START_SAVE" });
      updateVenue({
        variables: {
          changes,
          venueId: state.identifier
        }
      })
        .then(() => {
          refetch();
          toast.success("Updated successfully.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }, handleReject)
        .finally(() => {
          componentDispatch({ type: "STOP_SAVE" });
        });
    } else {
      componentDispatch({ type: "START_SAVE" });
      createVenue({
        variables: data
      })
        .then((response) => {
          componentDispatch({
            type: "SET_IDENTIFIER",
            payload: response.data.createVenue.venue.venueId
          });
          toast.success("Created successfully.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }, handleReject)
        .finally(() => {
          componentDispatch({ type: "STOP_SAVE" });
        });
    }
  }, [state, componentDispatch, updateVenue, createVenue]);

  const saveThenAddVenue = useCallback(() => {
    saveVenue();
    addVenue();
  }, [addVenue, saveVenue]);

  const handleAddClick = useCallback(() => {
    if (state.dirtyFields.length > 0) {
      componentDispatch({
        type: "SHOW_MODAL",
        payload: true
      });
    } else {
      addVenue();
    }
  }, [state.dirtyFields, componentDispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveVenue();
  };

  const handleChange = useCallback(
    (e) => {
      componentDispatch({
        type: "SET_ONE",
        payload: { name: e.target.name, value: e.target.value }
      });
    },
    [componentDispatch]
  );

  const handleSelect = useCallback((value, props) => {
    componentDispatch({
      type: "SET_ONE",
      payload: { name: props.name, value }
    });
  });

  const handleUpload = useCallback((name, file) => {
    componentDispatch({
      type: "SET_ONE",
      payload: { name, value: file }
    });
  });

  const handleCloseManager = useCallback(() => {
    componentDispatch({
      type: "SET_AREA_MANAGER_OPEN",
      payload: false
    });
  }, [componentDispatch]);

  const handleManageAreas = useCallback(() => {
    componentDispatch({
      type: "SET_AREA_MANAGER_OPEN",
      payload: true
    });
  }, []);

  const handleRemoveArea = useCallback(
    (venueAreaId) => {
      deleteVenueArea({
        variables: { venueAreaId }
      }).then(
        () => venueAreasQuery.refetch(),
        (reject) => handleReject(reject, "A problem occurred while deleting the venue area.")
      );
    },
    [deleteVenueArea, venueAreasQuery]
  );

  const addArea = useCallback(() => {
    const venueArea = venueAreasQuery.data.allVenueAreas.map((venueArea) => venueArea.text);
    if (venueArea.indexOf(state.newVenueArea) !== -1) {
      toast.error("That venue area already exists.", {
        position: "bottom-center",
        hideProgressBar: true,
        pauseOnHover: false,
        closeButton: false
      });
    } else if (state.newVenueArea.length === 0) {
      toast.error("Venue area cannot be blank.", {
        position: "bottom-center",
        hideProgressBar: true,
        pauseOnHover: false,
        closeButton: false
      });
    } else {
      createVenueArea({
        variables: {
          text: state.newVenueArea
        }
      }).then(
        () => venueAreasQuery.refetch(),
        () => {
          toast.error("A problem occurred while creating the venue area.", {
            position: "bottom-center",
            hideProgressBar: true,
            pauseOnHover: false,
            closeButton: false
          });
        }
      );
    }
  }, [state.newVenueArea, venueAreasQuery]);

  const createNewArea = useCallback((label) => {
    const option = { value: label, label };
    if (venueAreasQuery.data.allVenueAreas.indexOf(label) === -1) {
      createVenueArea({
        variables: {
          text: label
        }
      }).then(venueAreasQuery.refetch(), (reject) =>
        handleReject(reject, "A problem occurred while creating the venue area.")
      );
    }
    componentDispatch({
      type: "SET_ONE",
      payload: { name: "area", value: option }
    });
  });

  const showPreview = useCallback(() => {
    componentDispatch({
      type: "SHOW_PREVIEW",
      payload: true
    });
  }, [componentDispatch]);

  const hidePreview = useCallback(() => {
    componentDispatch({
      type: "SHOW_PREVIEW",
      payload: false
    });
  }, [componentDispatch]);

  return (
    <>
      {(loading || state.isSubmitting) && <Loader text={state.isSubmitting && "Saving..."} />}
      <TopBar>
        {/* <LeftButtons>
          {!isLimited && (
            <>
              <ManagerButton type="button" onClick={handleAddClick}>
                <MdAdd size={iconSize} />
                <ManagerButtonText>New</ManagerButtonText>
              </ManagerButton>
              <ManagerButton type="button" onClick={duplicateVenue}>
                <MdContentCopy size={iconSize} />
                <ManagerButtonText>Duplicate</ManagerButtonText>
              </ManagerButton>
            </>
          )}
        </LeftButtons> */}
        <Header>Course Manager</Header>
        <RightButtons>
          {!isLimited && (
            <>
              <ManagerButton type="button" onClick={handleAddClick}>
                <MdAdd size={iconSize} />
                <ManagerButtonText>New</ManagerButtonText>
              </ManagerButton>
              <ManagerButton type="button" onClick={duplicateVenue}>
                <MdContentCopy size={iconSize} />
                <ManagerButtonText>Duplicate</ManagerButtonText>
              </ManagerButton>
            </>
          )}
          <ManagerButton form="venue-manager-form" disabled={state.dirtyFields.length === 0}>
            <MdSave size={iconSize} />
            <ManagerButtonText>Save</ManagerButtonText>
          </ManagerButton>
          {!isLimited && (
            <ManagerButton disabled={state.identifier === undefined} onClick={showDelete}>
              <MdRemove size={iconSize} />
              <ManagerButtonText>Delete</ManagerButtonText>
            </ManagerButton>
          )}
        </RightButtons>
      </TopBar>
      <Form autocomplete="off" onSubmit={handleSubmit} id="venue-manager-form">
        {!isLimited && (
          <>
            <Label>
              <Text>Created</Text>
              <TextInput
                disabled
                value={`${
                  state.created
                    ? moment(new Date(state.created)).format("M/D/YY hh:mm A")
                    : "Has not been created"
                }`}
              />
            </Label>
            <Label>
              <Text>Last Update</Text>
              <TextInput
                disabled
                value={`${
                  state.lastUpdate
                    ? moment(new Date(state.lastUpdate)).format("M/D/YY hh:mm A")
                    : "Has not been updated"
                }`}
              />
            </Label>
            <MultipleInputWrapper required>
              <Text>Status</Text>
              <RadioLabel>
                <RadioInput
                  name="status"
                  value="active"
                  onChange={handleChange}
                  checked={state.status === "active"}
                  data-required={missingFields.includes("status") && state.status.length === 0}
                />
                <span>Active</span>
              </RadioLabel>
              <RadioLabel>
                <RadioInput
                  name="status"
                  value="inactive"
                  onChange={handleChange}
                  checked={state.status === "inactive"}
                  data-required={missingFields.includes("status") && state.status.length === 0}
                />
                <span>Inactive</span>
              </RadioLabel>
            </MultipleInputWrapper>
          </>
        )}
        <Label>
          <Text>Logo</Text>
          <ImageUpload id="logo-upload" name="logo" onChange={handleUpload} current={state.logo} />
        </Label>
        <Label>
          <Text>Image</Text>
          <ImageUpload id="image-upload" name="image" onChange={handleUpload} current={state.image} />
        </Label>
        <Label required>
          <Text>Name</Text>
          <TextInput
            required
            name="name"
            data-required={missingFields.includes("name") && state.name}
            value={state.name}
            onChange={handleChange}
          />
        </Label>
        <Label>
          <Text>Email Address</Text>
          <TextInput name="email" value={state.email} onChange={handleChange} />
        </Label>
        <Label>
          <Text>Website</Text>
          <TextInput name="website" value={state.website} onChange={handleChange} />
        </Label>
        <Label>
          <Text>Phone</Text>
          <TextInput name="phone" value={state.phone} onChange={handleChange} />
        </Label>
        <Label>
          <Text>Address</Text>
          <TextInput name="address" value={state.address} onChange={handleChange} />
        </Label>
        <Label>
          <Text>City</Text>
          <TextInput name="city" value={state.city} onChange={handleChange} />
        </Label>
        <Label>
          <Text>State</Text>
          <TextInput name="state" value={state.state} onChange={handleChange} />
        </Label>
        <Label>
          <Text>Zip Code</Text>
          <TextInput name="zipCode" value={state.zipCode} onChange={handleChange} />
        </Label>
        {!isLimited && (
          <>
            <Label>
              <Text>Notes</Text>
              <TextInput name="notes" value={state.notes} onChange={handleChange} />
            </Label>
            <Label required>
              <Text>Area</Text>
              <StyledCreatableSelect
                name="area"
                value={state.area}
                onChange={handleSelect}
                options={
                  venueAreasQuery.data &&
                  venueAreasQuery.data.allVenueAreas
                    .map((area) => ({
                      value: area.text,
                      label: area.text
                    }))
                    .sort((a, b) => {
                      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
                      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
                      return 0;
                    })
                }
                isClearable
                onCreateOption={createNewArea}
                isLoading={venueAreasQuery.loading}
                isDisabled={venueAreasQuery.loading}
                placeholder="Select or type to create new"
                styles={{
                  control: (base) => {
                    return {
                      ...base,
                      borderColor:
                        missingFields.includes("area") &&
                        (state.area === null ||
                          state.area === undefined ||
                          (typeof state.area === "string" && state.area.length === 0))
                          ? "red"
                          : base.borderColor
                    };
                  }
                }}
              />
              <Button bgColor={colors.secondary} color={colors.secondaryOffset} onClick={handleManageAreas}>
                Manage Areas
              </Button>
            </Label>
            <Label>
              <Text>Clients</Text>
              <StyledSelect
                name="clients"
                options={
                  getClients.data &&
                  getClients.data.clients.edges
                    .map((client) => ({
                      value: client.node.userId,
                      label: `${client.node.firstName} ${client.node.lastName}`
                    }))
                    .sort((a, b) => {
                      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
                      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
                      return 0;
                    })
                }
                isMulti
                isSearchable
                value={state.clients}
                onChange={handleSelect}
              />
            </Label>
          </>
        )}
        {isLimited && (
          <Button
            type="button"
            bgColor={colors.secondary}
            onClick={showPreview}
            style={{ marginLeft: "160px", marginTop: "20px", padding: "0px 2em" }}
          >
            View
          </Button>
        )}
        {state.isPreviewVisibe && <VenuePreview venue={state} onClose={hidePreview} />}
      </Form>
      {state.isAreaManagerVisible && (
        <ModalWrapper>
          <Modal>
            <ModalHeader>Area Manager</ModalHeader>
            <ManagerWrapper>
              <OfferTypeList>
                {venueAreasQuery.data &&
                  venueAreasQuery.data.allVenueAreas.map((area) => (
                    <OfferTypeItem key={area.venueAreaId}>
                      <OfferTypeSpan>{area.text}</OfferTypeSpan>
                      <Button onClick={() => handleRemoveArea(area.venueAreaId)}>Remove</Button>
                    </OfferTypeItem>
                  ))}
              </OfferTypeList>
            </ManagerWrapper>
            <AddOfferTypeWrapper>
              <TextInput name={"newVenueArea"} onChange={handleChange} width="100%" placeholder="Mountain" />
              <Button onClick={addArea}>Add</Button>
            </AddOfferTypeWrapper>
            <ManagerBtn onClick={handleCloseManager}>Close</ManagerBtn>
          </Modal>
        </ModalWrapper>
      )}
      {state.isModalVisible && (
        <ModalWrapper>
          <Modal>
            <ConfirmText>Add venue and...</ConfirmText>
            <ConfirmButtonWrapper>
              <Button onClick={saveThenAddVenue}>Save Current Changes</Button>
              <Button onClick={addVenue}>Just Add Venue</Button>
              <Button onClick={hideModal}>Cancel</Button>
            </ConfirmButtonWrapper>
          </Modal>
        </ModalWrapper>
      )}
      {state.isDeleteVisible && (
        <ModalWrapper>
          <Modal>
            <ConfirmText>Are you sure you want to delete this user?</ConfirmText>
            <ConfirmButtonWrapper>
              <Button onClick={handleDelete}>Delete</Button>
              <Button onClick={hideDelete}>Cancel</Button>
            </ConfirmButtonWrapper>
          </Modal>
        </ModalWrapper>
      )}
      <ToastContainer autoClose={2000} />
    </>
  );
};
