import React from "react";
import { Route, Switch } from "react-router-dom";
import { Loader } from "../loaders";
import { AdminLandingRouter, ClientLandingRouter, MemberLandingRouter, LoginRouter } from "../";

function loader() {
  return <Loader />;
}

export const Routes = () => {
  return (
    <Switch>
      <Route path="/admin" component={AdminLandingRouter} />
      <Route path="/client" component={ClientLandingRouter} />
      <Route path="/member" component={MemberLandingRouter} />
      <Route path="/loader" component={loader} />
      <Route default component={LoginRouter} />
    </Switch>
  );
};
