import React, { Suspense } from "react";
import { colors } from "../../colors";

const LazyMemberLanding = React.lazy(() => import("./landing"));

export const MemberLandingRouter = () => {
  return (
    <Suspense
      fallback={<div style={{ width: "100vw", height: "100vh", backgroundColor: colors.memberBg }}></div>}
    >
      <LazyMemberLanding />
    </Suspense>
  );
};
