import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ButtonLink, UseDetails, UseDetailsAvailable, UseDetailsRedeemed } from "../../shared";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { ContainerLoader } from "../../loaders";
import {
  getCookie,
  getExpirationString,
  getOfferValidationMessage,
  getNumRedeemedAfterReset
} from "../../../utility";
import { useAnchorContext } from "../";

const GET_OFFER_USAGE = gql`
  query($offerId: ID, $userCookie: String) {
    offerAndUsage(offerId: $offerId, userCookie: $userCookie) {
      offerId
      allowedRedemptions
      redemptionPeriod
      usesPerDay
      resetDay
      resetTime
      startDate
      endDate
      timeValidStart
      timeValidEnd
      validDays
      redemptions {
        date
        time
      }
    }
  }
`;

const margin = "0 0 5px 0";
const OfferType = styled.span`
  color: black;
  font-size: 20px;
  font-weight: bold;
  margin: ${margin};
`;

const Availability = styled.span`
  color: ${({ color }) => color};
  font-size: ${({ size }) => size};
  font-weight: bold;
  margin: ${margin};
`;

const Expiration = styled.span`
  color: red;
  font-size: 15px;
  font-weight: bold;
  margin: ${margin};
`;

const UseLimit = styled.span`
  font-size: 16px;
  font-weight: 500;
  margin: ${margin};
`;

const AccessButton = styled(ButtonLink)`
  position: relative;
  width: 100%;
  font-size: 18px;
  margin: 0;
  bottom: 0;
`;

const Anchor = styled.div`
  position: absolute;
  bottom: 0;
`;

const OfferDetailsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 225px;
  justify-content: space-between;
`;

export const OfferDetails = ({ venueId, offer }) => {
  const [state, setState] = useState({
    offerType: offer.offerType ? JSON.parse(offer.offerType).label : "",
    availablility: undefined,
    expiration: "",
    useLimit: "",
    numAvailable: "n/a",
    numRedeemed: "n/a",
    numExpired: "n/a"
  });
  const { loading, error, data } = useQuery(GET_OFFER_USAGE, {
    variables: { offerId: offer.offerId, userCookie: getCookie("uuid") },
    fetchPolicy: "no-cache"
  });
  const [, anchorDispatch] = useAnchorContext();
  const setAnchor = useCallback((e) => {
    anchorDispatch({
      type: "SET_STATE",
      payload: { anchor: e.target.dataset.id }
    });
  });

  useEffect(() => {
    if (data) {
      let availablility = getOfferValidationMessage(data.offerAndUsage).message;
      if (availablility === "Expired") {
        availablility = undefined;
      }
      const numAllowed = data.offerAndUsage.allowedRedemptions;
      const numRedeemedAfterReset = getNumRedeemedAfterReset(
        data.offerAndUsage.redemptions,
        JSON.parse(data.offerAndUsage.redemptionPeriod)?.value,
        data.offerAndUsage.allowedRedemptions,
        data.offerAndUsage.resetDay
      );
      const numRedeemed = data.offerAndUsage.redemptions.length;
      let useLimit = `${numAllowed} ${JSON.parse(data.offerAndUsage.redemptionPeriod).label} `;
      if (data.offerAndUsage.usesPerDay) {
        useLimit += ` | ${data.offerAndUsage.usesPerDay} Per Day`;
      }
      setState({
        ...state,
        availablility,
        expiration: getExpirationString(data.offerAndUsage.endDate),
        useLimit,
        numAvailable: numAllowed - numRedeemedAfterReset,
        numRedeemed: numRedeemed
      });
    }
  }, [data]);

  if (error) {
    return <div>{error.message}</div>;
  }
  return (
    <OfferDetailsWrapper>
      {/* {loading && <ContainerLoader />} */}
      <OfferType key="offerType">{state.offerType}</OfferType>
      {state.availablility && state.availablility !== "Now Available" && (
        <Availability size={"20px"} key="notavail" color="red">
          Not Available
        </Availability>
      )}
      <Availability
        size={state.availablility && state.availablility !== "Now Available" ? "15px" : "20px"}
        color={"green"}
        key="availability"
      >
        {state.availablility}
      </Availability>
      <Expiration key="expiration">{state.expiration}</Expiration>
      <UseLimit key="useLimit">{state.useLimit}</UseLimit>
      <UseDetails key="useDetails">
        <UseDetailsAvailable key="useDetailsAvailable">Available: {state.numAvailable}</UseDetailsAvailable>
        <UseDetailsRedeemed key="useDetailsRedeemed">Redeemed: {state.numRedeemed}</UseDetailsRedeemed>
        {/* <UseDetailsExpired key="useDetailsExpired">Expired: {state.numExpired}</UseDetailsExpired> */}
      </UseDetails>
      <AccessButton
        to={`/member/venue/${venueId}#${offer.offerId}`}
        data-id={offer.offerId}
        onClick={setAnchor}
      >
        <Anchor id={offer.offerId}></Anchor>
        Access Offer
      </AccessButton>
    </OfferDetailsWrapper>
  );
};
