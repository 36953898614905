import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { CheckboxInput, StyledSelect } from "../../shared";

const checkboxSize = "20px";
const StyledCheckboxInput = styled(CheckboxInput)`
  height: ${checkboxSize};
  width: ${checkboxSize};
`;

const HoleNumber = styled.span`
  justify-self: center;
  font-weight: 900;
`;

const HoleWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(18, 110px);
  background-color: rgba(255, 255, 255, 0.95);
  border-left: 1px solid rgba(0, 0, 0, 0);
  border-right: 1px solid rgba(0, 0, 0, 0);
`;

const Hole = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.25rem;
  padding: 0.25rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.15);

  &:nth-child(18n - 17) {
    border-left: none;
  }

  &:nth-child(18n) {
    border-right: none;
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;

const PlayerName = styled.span`
  display: flex;
  position: sticky;
  left: 0;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding-right: 0.5rem;
  background-color: rgba(0, 0, 0, 0.95);
  color: white;
  font-weight: bold;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  grid-column: span 2;
  padding: 0.25rem 0.5rem;
  box-sizing: border-box;
  font-size: 22px;
  width: 100vw;
`;

const TotalsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  right: 0;
  justify-content: space-evenly;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  border-left: 1px solid rgba(0, 0, 0, 0.15);
`;

const Divider = styled.div`
  grid-column: 1 / -1;
  height: 0.5rem;
`;

const Totals = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-top: 1px solid rgba(0, 0, 0, 0.15);
`;

const HoleSelect = styled(StyledSelect)`
  min-width: unset;
  font-weight: 900;
`;

const Scores = styled.div`
  display: flex;
  grid-gap: 0.25rem;
  width: 100%;
  justify-content: space-evenly;
`;

const ScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 0.2rem;
`;

const ScoreLabel = styled.span`
  font-size: 12px;
`;

const Score = styled.span`
  font-weight: bold;
  font-size: 24px;
  width: 100%;
  text-align: center;
`;

const GameValue = styled.span`
  font-size: 22px;
`;

const GameName = styled.span`
  font-size: 12px;
  width: 8ch;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
`;

const GameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0.25rem;
`;

const GamesWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;

const ScoreOptions = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
  { value: 13, label: 13 },
  { value: 14, label: 14 },
  { value: 15, label: 15 }
];

export const PlayerScorecard2 = ({
  playerName,
  scores,
  onScoreChange,
  onMoneyGameChange,
  numSetsOfNine,
  game1Name,
  game2Name,
  game1Value,
  game2Value,
  playerGame
}) => {
  const [moneyGameMap, setMoneyGameMap] = useState(playerGame);

  const [scoreMap, setScoreMap] = useState(scores);

  const handleScoreChange = useCallback(
    (number) => (value) => {
      const newScores = { ...scoreMap, [number]: value };
      setScoreMap(newScores);
      if (onScoreChange) {
        onScoreChange({ scores: newScores, playerName });
      }
    },
    [onScoreChange, playerName, scoreMap]
  );

  const handleMoneyGameChange = useCallback(
    ({ game, number }) =>
      () => {
        const newGames = {
          ...moneyGameMap,
          [game]: { ...moneyGameMap[game], [number]: !!!moneyGameMap[game][number] }
        };
        setMoneyGameMap(newGames);
        if (onMoneyGameChange) {
          onMoneyGameChange({ games: newGames, playerName });
        }
      },
    [moneyGameMap, onMoneyGameChange, playerName]
  );

  const Holes = useMemo(() => {
    let holes = [];
    for (let i = 0; i < numSetsOfNine; i++) {
      //   const scores = [];
      for (let j = 1; j < 10; j++) {
        const number = i * 9 + j;
        // scores.push(
        const styles = {
          control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: scoreMap[number]?.value ? "#b5ffb5" : baseStyles.backgroundColor
          }),
          menu: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: 1
          })
        };
        holes.push(
          <Hole key={`${number}`}>
            <HoleNumber key="hole-num">{number}</HoleNumber>
            <HoleSelect
              key="hole-select"
              placeholder="0"
              value={scoreMap[number]}
              onChange={handleScoreChange(number)}
              options={ScoreOptions}
              isSearchable={false}
              menuPlacement="auto"
              menuPosition="fixed"
              styles={styles}
            />
            <CheckboxWrapper key="cbs">
              <StyledCheckboxInput
                key="game1-cb"
                onClick={handleMoneyGameChange({ game: "game1", number })}
                checked={!!moneyGameMap.game1[number]}
              />
              <StyledCheckboxInput
                key="game2-cb"
                onClick={handleMoneyGameChange({ game: "game2", number })}
                checked={!!moneyGameMap.game2[number]}
              />
            </CheckboxWrapper>
          </Hole>
        );
      }
      //   holes = holes.concat(scores);
    }
    return holes;
  }, [
    handleMoneyGameChange,
    handleScoreChange,
    moneyGameMap.game1,
    moneyGameMap.game2,
    numSetsOfNine,
    scoreMap
  ]);

  const AllTotals = useMemo(() => {
    const totals = [];
    let front = 0;
    let back = 0;
    let score = 0;
    let game1 = 0;
    let game2 = 0;
    for (let i = 0; i < numSetsOfNine; i++) {
      //   let front = 0;
      //   let back = 0;
      //   let score = 0;
      //   let game1 = 0;
      //   let game2 = 0;
      for (let j = 1; j < 10; j++) {
        const key = i * 9 + j;
        if (i === 0) {
          front += scoreMap[key]?.value || 0;
        }
        if (i === 1) {
          back += scoreMap[key]?.value || 0;
        }
        score += scoreMap[key]?.value || 0;
        if (moneyGameMap.game1[key]) {
          game1 += parseInt(game1Value) || 0;
        }
        if (moneyGameMap.game2[key]) {
          game2 += parseInt(game2Value) || 0;
        }
      }
    }
    totals.push(
      <Totals key="totals">
        <Scores>
          <ScoreWrapper>
            <ScoreLabel>Front 9</ScoreLabel>
            <Score>{front}</Score>
          </ScoreWrapper>
          <ScoreWrapper>
            <ScoreLabel>Back 9</ScoreLabel>
            <Score>{back}</Score>
          </ScoreWrapper>
          <ScoreWrapper>
            <ScoreLabel>Total</ScoreLabel>
            <Score>{score}</Score>
          </ScoreWrapper>
        </Scores>
        <GamesWrapper>
          <GameWrapper>
            <GameName>{game1Name}</GameName>
            <GameValue>${game1}</GameValue>
          </GameWrapper>
          <GameWrapper>
            <GameName>{game2Name}</GameName>
            <GameValue>${game2}</GameValue>
          </GameWrapper>
        </GamesWrapper>
      </Totals>
    );
    return totals;
  }, [
    game1Name,
    game1Value,
    game2Name,
    game2Value,
    moneyGameMap.game1,
    moneyGameMap.game2,
    numSetsOfNine,
    scoreMap
  ]);

  return (
    <>
      <PlayerName>{playerName}</PlayerName>
      <HoleWrapper>{Holes}</HoleWrapper>
      <TotalsWrapper>{AllTotals}</TotalsWrapper>
      <Divider></Divider>
    </>
  );
};
