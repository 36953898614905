import React, { useReducer, useCallback } from "react";
import { BiSelectMultiple } from "react-icons/bi";
import { TableButton } from "../table";
import { FileUpload } from "../inputs";
import { toast } from "react-toastify";
import { useSelectViewModelContext, useTableViewModelContext } from "..";
import { Button, Modal, ModalWrapper } from "../shared";

const defaultState = {
    selectId: "hidden_select_file_input",
    badEmailList: [],
    isModalVisible: false
};

const selectEmailDispatch = (state, action) => {
    switch (action.type) {
        case "SET_SELECT_ID": {
            const newState = { ...state };
            newState.selectId = action.payload;
            return newState;
        }
        case "SHOW_MODAL": {
            return {
                ...state,
                isModalVisible: action.payload
            };
        }
        case "SET_BAD_EMAILS": {
            return {
                ...state,
                badEmailList: action.payload
            }
        }
        default:
            return { ...state };
    }
};

const buttonSize = "2em";
export const SelectButton = ({ table }) => {
    const [{ selectId, badEmailList, isModalVisible }, componentDispatch] = useReducer(selectEmailDispatch, Object.assign({}, defaultState));
    const [, selectDispatch] = useSelectViewModelContext();
    const [tableData] = useTableViewModelContext();

    const showBadEmailList = useCallback(() => {
        componentDispatch({
            type: "SHOW_MODAL",
            payload: true
        });
    }, [componentDispatch]);

    const hideModal = useCallback(() => {
        componentDispatch({
            type: "SHOW_MODAL",
            payload: false
        });
    }, [componentDispatch]);

    const openFileDialog = useCallback(() => {
        document.getElementById(selectId).click();
    }, [selectId]);

    const handleFileUpload = useCallback(
        (file) => {
            file = file.replaceAll("\r", "");
            const emails = file.split("\n");
            const ids = [];
            const badEmails = [];
            const filteredTableRows = tableData[table].filtered.map(row => row.node);
            emails.forEach(email => {
                const re = new RegExp(email, "i");
                const rowToSelect = filteredTableRows.find(row => re.test(row.email));
                if (rowToSelect) {
                    if (!ids.includes(rowToSelect.userId)) {
                        ids.push(rowToSelect.userId);
                    }
                } else {
                    badEmails.push(email);
                }
            });
            selectDispatch({
                type: "SELECT_IDS",
                payload: { ids, table }
            });
            componentDispatch({
                type: "SET_BAD_EMAILS",
                payload: badEmails
            });
            if (ids.length > 0) {
                toast.success(`${ids.length} row(s) selected.`, {
                    position: "bottom-center",
                    hideProgressBar: true,
                    pauseOnHover: false,
                    closeButton: false
                });
            }
            if (badEmails.length > 0) {
                toast.error(<div>{`${badEmails.length} email(s) not found.`}<a style={{ color: "blue" }} onClick={showBadEmailList}>&nbsp;View list here</a></div>, {
                    position: "bottom-center",
                    hideProgressBar: false,
                    pauseOnHover: true,
                    closeButton: false,
                    autoClose: 5000
                });
            }
        },
        []
    );

    return (
        <>
            <TableButton
                key={`${table}_export`}
                icon={<BiSelectMultiple size={buttonSize} />}
                text="Select Email List"
                handleClick={openFileDialog}
            >
                <FileUpload id={selectId} name="import" onChange={handleFileUpload} />
            </TableButton>
            {isModalVisible && <ModalWrapper>
                <Modal>
                    <div style={{ marginBottom: "15px", minWidth: "300px", maxHeight: "60vh", overflowY: "scroll" }}>
                        {badEmailList.map(email => <span>{email}<br /></span>)}
                    </div>
                    <Button onClick={hideModal}>Done</Button>
                </Modal>
            </ModalWrapper>}
        </>
    );
};
