import React from "react";
import styled from "styled-components";

const VenueImgWrapper = styled.div`
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VenueImg = styled.img`
  width: 95%;
`;

const VenueHeader = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 28px;
  color: orange;
  text-align: center;
`;

const VenueCity = styled.span`
  font-size: 20px;
  color: black;
  font-weight: bold;
`;

export const VenueDetails = ({ venue }) => {
  return (
    <>
      <VenueImgWrapper>
        <VenueImg src={`${process.env.REACT_APP_IMAGE_ENDPOINT}/${venue.logo}`} alt="venue logo" />
      </VenueImgWrapper>
      <VenueHeader>{venue.name}</VenueHeader>
      <VenueCity>{venue.city}</VenueCity>
    </>
  );
};
