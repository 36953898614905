import React, { memo, useCallback, useEffect, useState } from "react";
import { TableRow } from ".";
import { useSelectViewModelContext } from "..";
import { useTableViewModelContext } from "./tableContext";

const IdSetter = ({ table }) => {
  const [tableData] = useTableViewModelContext();
  const [, selectDispatch] = useSelectViewModelContext();

  useEffect(() => {
    const ids = tableData[table].filtered.map((row) => row.node[tableData[table].identifier]);
    selectDispatch({
      type: "SET_IDS",
      payload: { table, ids }
    });
  }, [tableData]);

  return <></>;
};

const TableRows = ({ table, areRowsSelectable }) => {
  const [tableData] = useTableViewModelContext();
  const [maxRow, setMaxRow] = useState(200);
  const [scrollPos, setScrollPos] = useState(0);

  const incremenetMaxRow = useCallback(
    (e) => {
      if (maxRow < tableData[table].filtered?.length && window.scrollY >= 2000) {
        setScrollPos(window.scrollY);
        setMaxRow(maxRow + 20);
      } else if (scrollPos !== 0 && window.scrollY < 1000) {
        if (maxRow <= 220) {
          setScrollPos(0);
          setMaxRow(200);
        } else {
          setMaxRow(maxRow - 20);
        }
      }
    },
    [scrollPos, tableData, maxRow]
  );

  useEffect(() => {
    window.onscroll = incremenetMaxRow;
  }, [incremenetMaxRow]);

  return (
    <React.Fragment>
      {tableData[table].filtered.map((row, idx) => {
        if (maxRow - 200 <= idx && idx <= maxRow) {
          return (
            <TableRow
              key={row.node[tableData[table].identifier]}
              table={table}
              columns={tableData[table].columns}
              id={row.node[tableData[table].identifier]}
              row={row.node}
              index={idx}
              isSelectable={areRowsSelectable}
            />
          );
        } else return;
      })}
      <IdSetter table={table} />
    </React.Fragment>
  );
};

export default memo(TableRows);
