import React, { Suspense } from "react";
import { colors } from "../../colors";

const LazyAdminLanding = React.lazy(() => import("./landing"));

export const AdminLandingRouter = () => {
  return (
    <Suspense
      fallback={<div style={{ width: "100vw", height: "100vh", backgroundColor: colors.memberBg }}></div>}
    >
      <LazyAdminLanding />
    </Suspense>
  );
};
