import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useCallback, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import available from "../../../assets/ready.png";
import expired from "../../../assets/expired.png";
import notyet from "../../../assets/comingsoon.png";
import redeemed from "../../../assets/redeemed.png";
import { getCookie, getNumRedeemedAfterReset, getOfferValidationMessage } from "../../../utility";
import { Loader } from "../../shared";
import { HashLink } from "react-router-hash-link";
import { useAnchorContext } from "../";

const GET_OFFER_USAGE = gql`
  query($offerId: ID, $userCookie: String) {
    offerAndUsage(offerId: $offerId, userCookie: $userCookie) {
      offerId
      maxValue
      allowedRedemptions
      redemptionPeriod
      usesPerDay
      resetDay
      resetTime
      startDate
      endDate
      timeValidStart
      timeValidEnd
      validDays
      redemptions {
        date
        time
      }
    }
  }
`;

const Anchor = styled.div`
  position: absolute;
  bottom: 50vh;
`;

const FlexSection = styled.section`
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  position: relative;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.5em;
`;

const sharedStyles = `
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    padding: 5px 20px;
`;

const RedemptionStatus = styled.img`
  height: 65px;
  width: auto;
`;

const AvailabilityStatus = styled.span`
  color: ${({ color }) => color};
  font-weight: 450;
  text-align: start;
  white-space: nowrap;
`;

const Header = styled.h2`
  ${sharedStyles}
  text-align: left;
  font-size: 1.25em;
  padding: 0;
`;

const UseInfo = styled.span`
  text-transform: capitalize;
  font-size: 1em;
  white-space: nowrap;
`;

const AccessOffer = styled(HashLink)``;

const RedeemText = styled.div`
  display: flex;
  justify-content: start;
`;

const redemptionImages = {
  "Now Available": available,
  "Not Yet": notyet,
  Expired: expired,
  Redeemed: redeemed
};

export const OfferOverview = ({ venueId, offer }) => {
  const { loading, error, data } = useQuery(GET_OFFER_USAGE, {
    variables: { offerId: offer.offerId, userCookie: getCookie("uuid") },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  });
  const [validationMessage, setValidationMessage] = useState("redemption status");
  const [showCheckmark, setShowCheckmark] = useState(false);
  const [checkmarkFlag, setCheckmarkFlag] = useState(0);
  const [state, setState] = useState({
    numAvailable: "n/a",
    numRedeemed: "n/a"
  });

  const [, anchorDispatch] = useAnchorContext();
  const setAnchor = useCallback((e) => {
    anchorDispatch({
      type: "SET_STATE",
      payload: { anchor: e.target.dataset.id }
    });
  });

  useEffect(() => {
    if (data) {
      const numAllowed = data.offerAndUsage.allowedRedemptions;
      const numRedeemedAfterReset = getNumRedeemedAfterReset(
        data.offerAndUsage.redemptions,
        JSON.parse(data.offerAndUsage.redemptionPeriod)?.value,
        data.offerAndUsage.allowedRedemptions,
        data.offerAndUsage.resetDay
      );
      const numRedeemed = data.offerAndUsage.redemptions.length;
      setState({
        numAvailable: numAllowed - numRedeemedAfterReset,
        numRedeemed: numRedeemed
      });
      const message = getOfferValidationMessage(data.offerAndUsage);
      setValidationMessage(message);
    }
  }, [data]);

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <>
      {loading && <Loader />}
      <FlexSection>
        <div>
          <AccessOffer
            to={`/member/venue/${venueId}#${offer.offerId}`}
            data-id={offer.offerId}
            onClick={setAnchor}
          >
            <Anchor id={offer.offerId}></Anchor>
            <RedemptionStatus
              data-id={offer.offerId}
              src={redemptionImages[validationMessage.key]}
              alt={validationMessage.message}
            />
          </AccessOffer>
        </div>
        <TextWrapper>
          <Header>{JSON.parse(offer.offerType).label}</Header>
          <RedeemText>
            {data && (
              <UseInfo>
                {`${data.offerAndUsage.allowedRedemptions} ${
                  JSON.parse(data.offerAndUsage.redemptionPeriod) &&
                  JSON.parse(data.offerAndUsage.redemptionPeriod)
                    .label?.replace(/([A-Z]+)/g, " $1")
                    .replace(/([A-Z][a-z])/g, " $1")
                }`}
              </UseInfo>
            )}
            <UseInfo>&nbsp;-&nbsp;{state.numRedeemed} Redeemed</UseInfo>
          </RedeemText>
          <AvailabilityStatus color={validationMessage.color}>{validationMessage.message}</AvailabilityStatus>
        </TextWrapper>
      </FlexSection>
    </>
  );
};
