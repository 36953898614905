import React from "react";
import { BrowserRouter } from "react-router-dom";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import {
  TableProvider,
  SessionProvider,
  SelectProvider,
  FilterProvider,
  SearchProvider,
  AnchorProvider,
  ClientProvider,
  VenueNavProvider,
  Routes,
  VersionCheck,
  Copyright
} from "./components";
import { getCookie } from "./utility";

function App() {
  const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    headers: {
      AUTHTOKEN: getCookie("uuid")
    }
  });

  return (
    <ApolloProvider client={client}>
      <SessionProvider>
        <TableProvider>
          <FilterProvider>
            <SelectProvider>
              <ClientProvider>
                <BrowserRouter>
                  <AnchorProvider>
                    <SearchProvider>
                      <VenueNavProvider>
                        {/* <VersionCheck /> */}
                        <Routes />
                      </VenueNavProvider>
                    </SearchProvider>
                  </AnchorProvider>
                </BrowserRouter>
              </ClientProvider>
            </SelectProvider>
          </FilterProvider>
        </TableProvider>
      </SessionProvider>
    </ApolloProvider>
  );
}

export default App;
