import React, { useCallback, useEffect, useState, useRef } from "react";
import { CheckboxColumn, TableCell, TableCheckbox } from ".";
import { colors } from "../../colors";

export const TableRow = ({ id, table, columns, row, index, isSelectable }) => {
  const ref = useRef(undefined);
  const [scrollWidth, setScrollWidth] = useState("0px");
  const [isActive, setActive] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setScrollWidth(`${ref.current.parentElement.scrollWidth}px`);
    }
  }, [ref]);

  const handleSelect = useCallback(() => {
    const x = window.scrollX,
      y = window.scrollY;
    document.getElementById(`${table}_checkbox${id}`).click();
    window.scrollTo(x, y);
  }, [table, row]);

  const [backgroundColor, setBackgroundColor] = useState(
    index % 2 === 1 ? colors.tableOdd : colors.tableEven
  );

  const handleMouseOver = useCallback(() => {
    setActive(true);
  }, [setActive]);

  const handleMouseOut = useCallback(() => {
    setActive(false);
  }, [setActive]);

  useEffect(() => {
    setBackgroundColor(index % 2 === 1 ? colors.tableOdd : colors.tableEven);
  }, [index]);
  return (
    <>
      {isSelectable && (
        <CheckboxColumn
          ref={ref}
          scrollWidth={scrollWidth}
          aria-label={`Select row id ${id}`}
          key={`${table}_cell_checkbox_${id}`}
          width="40px"
          backgroundColor={backgroundColor}
        >
          <TableCheckbox id={id} table={table} isActive={isActive} />
        </CheckboxColumn>
      )}
      {columns.map((column) => {
        let value = "";
        if (typeof column.accessor === "function") {
          value = column.accessor(row);
        } else {
          value = row[column.accessor];
        }
        return (
          <TableCell
            handleClick={isSelectable ? handleSelect : undefined}
            key={`${id}_${column.header}`}
            title={value}
            text={value}
            backgroundColor={backgroundColor}
            link={column.link && column.link(row)}
            disabled={column.disabled && column.disabled(row)}
            style={column.style}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          />
        );
      })}
    </>
  );
};
