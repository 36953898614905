import React, { createContext, useContext, useReducer } from "react";
import gql from "graphql-tag";

export const sessionViewModel = createContext();
sessionViewModel.displayName = "Session Context";
export const useSessionViewModelContext = () => {
  return useContext(sessionViewModel);
};

const defaultState = {
  cookie: null
};

const DispatchFn = (state, action) => {
  switch (action.type) {
    case "SET_SESSION": {
      return Object.assign({}, state, action.payload);
    }
    default:
      return { ...state };
  }
};

export const SessionProvider = ({ children, defaultData }) => {
  const [state, dispatch] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));

  // useEffect(() => {
  //     dispatch({
  //         type: "ADD_SELF",
  //         payload: dispatch
  //     });
  // })

  return <sessionViewModel.Provider value={[state, dispatch]}>{children}</sessionViewModel.Provider>;
};
