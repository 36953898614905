import React, { useCallback } from "react";
import { MdFilterList } from "react-icons/md";
import { useFilterViewModelContext, TableButton } from "../table";

const buttonSize = "2em";
export const FilterButton = ({ table }) => {
    const [, filterDispatch] = useFilterViewModelContext();

    const toggleTableFilter = useCallback(() => {
        filterDispatch({
            type: "TOGGLE_FILTER",
            payload: table
        });
    }, [filterDispatch, table]);

    return <TableButton key={`${table}_Toggle Filter`} icon={<MdFilterList size={buttonSize} />} text="Toggle Filter" handleClick={toggleTableFilter} />;
};
