import React, { createContext, useContext, useReducer } from "react";

export const selectViewModel = createContext();
selectViewModel.displayName = "Select Context";
export const useSelectViewModelContext = () => {
  return useContext(selectViewModel);
};

const defaultState = {
  member: [],
  client: [],
  venue: [],
  offer: [],
  redemption: [],
  clientOffer: [],
  allIds: {
    member: [],
    client: [],
    venue: [],
    offer: [],
    redemption: [],
    clinetOffer: []
  }
};

const DispatchFn = (state, action) => {
  switch (action.type) {
    case "TOGGLE_SELECT": {
      const { table, id } = action.payload;
      const newState = { ...state };
      const existsAt = newState[table].indexOf(id);
      if (existsAt === -1) {
        if (id === -1) {
          // select all
          const selected = newState.allIds[table].map((id) => id);
          selected.push(-1);
          newState[table] = selected;
        }
        // select one
        newState[table].push(id);
      } else {
        if (id === -1) {
          // de-select all
          newState[table] = [];
        } else {
          // de-select one
          newState[table].splice(existsAt, 1);
          const selectAllExistsAt = newState[table].indexOf(-1);
          if (selectAllExistsAt !== -1) {
            newState[table].splice(selectAllExistsAt, 1);
          }
        }
      }
      return newState;
    }
    case "SELECT_IDS": {
      const { ids, table } = action.payload;
      const newState = { ...state };
      newState[table] = ids;
      return newState;
    }
    case "DELETE_SELECTED": {
      const newState = { ...state };
      newState.allIds = {
        ...newState.allIds,
        [action.payload]: newState.allIds[action.payload].filter(
          (id) => !newState[action.payload].includes(id)
        )
      };
      newState[action.payload] = [];
      return newState;
    }
    case "DELETE_IDS": {
      const { table, ids } = action.payload;
      const newState = { ...state };
      newState.allIds[table] = newState.allIds[table].filter((id) => !ids.includes(id));
      newState[table] = newState[table].filter((id) => !ids.includes(id));
      return newState;
    }
    case "SET_IDS": {
      const { table, ids } = action.payload;
      return {
        ...state,
        allIds: {
          ...state.allIds,
          [table]: ids
        }
      };
    }
    default:
      return { ...state };
  }
};

export const SelectProvider = ({ children, defaultData }) => {
  const [state, dispatch] = useReducer(DispatchFn, Object.assign({}, defaultState, defaultData));

  return <selectViewModel.Provider value={[state, dispatch]}>{children}</selectViewModel.Provider>;
};
